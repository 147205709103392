.shoppage_filterbar_bottom{
display: none;
}
.shoppage_header{
  position: sticky;
  top: 0;
  background-color: #c90053cf;
}
.shoppage_gridpage_gridview {
  display: flex;
  width: 100%;
}

.shoppage_gridpage_col_fverticalbar {
  width: 15%;
  display: flex;
  flex-direction: column;
  margin-left: 5%;
}

.shoppage_gridpage_col_listview_listview {
  margin-left: 5%;
  width: 60%;
}

.shoppage_gridpage_col_listview {
  width: 100%;
}

.shoppage_menubar_mobview {
  display: none;
}

.shoppage_gridpage_col_loadmore {
  width: 100%;
  /* background-color: yellow; */
  margin-top: 80px;
  display: flex;
  justify-content: center;
}
.shoppage_whatsapp{
  position: sticky;
  bottom: 40px;
  display: block;
  margin-top: -60px;
  /* z-index: 1; */
}
/******************************************************/
@media only screen and (max-width:1500px){
  .shoppage_gridpage_col_loadmore{
    margin-bottom: 10px;
  }
}

@media only screen and (max-width:590px){
  .shoppage_gridpage_col_fverticalbar{
    width: 20%;
    display: flex;
  }
}

@media only screen and (max-width:545px){
  .shoppage_gridpage_col_listview_listview{
    width: 70%;
  }
}

@media only screen and (max-width: 500px) {
  .shoppage_filterbar_bottom{
    display: block;
    bottom: 0;
    position: sticky;
    background-color: #FFFF;
    margin-bottom: 0px;
    /* margin-top: 0px; */
    }
  .shoppage_filterhorizontal {
    /* display: none; */
    display: contents;
    /* width: 50%; */
  }
  .shoppage_gridpage_col_listview{
    width: 100%;
  }
  .shoppage_gridpage_col_listview_gridview{
    width: 100%;
    margin: 0px;
  }

  .shoppage_gridpage_col_fverticalbar {
    display: none;
  }

  .shoppage_gridpage_col_listview_listview {
    margin-left: -30px;
    width: 60%;
  }

  .shoppage_gridpage_gridview {
    /* justify-content:left; */
    /* padding-left: 30px; */
  }

  .shoppage_menubar_mobview {
    display: flex;
    position: absolute;
    margin-top: -220px;
    width: 100%;
  }
  /* .shoppage_brandlist{
    width: 90%;
  } */
  .shoppage_gridpage_col_loadmore{
    margin-bottom: 10px;
  }
}


@media only screen and (max-width: 350px) {
  .shoppage_menubar_mobview {
    display: flex;
    position: absolute;
    margin-top: -240px;
    width: 100%;
  }

  .shoppage_filterhorizontal {
    display: contents;
  }

  .shoppage_gridpage_col_fverticalbar {
    display: none;
  }

  .shoppage_gridpage_gridview {
    display: contents;
  }

  .shoppage_listpage_col_fverticalbar {
    display: none;
  }

  .shoppage_gridpage_col_listview {}

  .shoppage_listpage_col {
    display: flex;
    flex-direction: column;
  }

  .singlelist {
    display: block;
  }

  .shoppage_listpage_col_listview {}
}

@media only screen and (max-width:290px){
  .shoppage_filterbar_bottom{
    width: 98%;
  }
}

@media only screen and (max-width:290px){
  .shoppage_gridpage_col_listview_listview {
    margin-left: 0px;
    width: 92.9%;
    margin: 0px;
  }
}