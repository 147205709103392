.subbanner {
  display: flex;
  flex-direction: column;
  background: #fb49970d;
  padding: 15px 15%;
}


.subbanner_title label {
  font-size: 1.8rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #fb2e86;
  font-weight: 650;
}

@media only screen and (max-width: 500px) {
  .subbanner_title{
    display: block;
  }
}

@media only screen and (max-width:290px){
  .subbanner_title label{
    font-size: 1.7rem;
  }
}