.productthumb_row1 {
  background-color: #f6f5ff;
  padding-left: 350px;
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  padding-top: 65px;
  padding-bottom: 100px;
}

.productthumb_row1 h1 {
  margin: 0;
}

.productthumb_row1_label2 {
  font-size: 0.9rem;
  padding-top: 5px;
}

.productthumb_row1_label2 span {
  color: #fb2e86;
  padding-left: 2px;
}

.productthumb_row2 {
  display: flex;
  justify-content: center;
  padding-top: 35px;
  padding-bottom: 70px;
}

.productthumb_row2_outer {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  width: 55%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  padding-bottom: 5px;
  padding-left: 5px;
}

.productthumb_row2_outer_col1 {
  padding-left: 10px;
  padding-top: 10px;
}

.productthumb_row2_outer_col1 img {
  padding-bottom: 5px;
}

.productthumb_row2_outer_col2 {
  padding-top: 15px;
  padding-right: 25px;
  padding-left: 5px;
  /* padding: 10px; */
}

.productthumb_row2_outer_col3 {
  padding-top: 20px;
  padding-left: 15px;
  display: flex;
  flex-direction: column;
}

.productthumb_row2_outer_col3 h2 {
  margin: 0px;
  /* padding-bottom: 30px; */
  color: #fb2e86;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: -20px;
}
.productthumb_row2_outer_col3 h5{
  margin-top: 20px;
  color: #fb2e86;
  font-size: 0.85rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding-bottom: 20px;
}

.productthumb_row2_outer_col3_row1 {
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  align-content: center;
}

.productthumb_row2_outer_col3_row1_span {
  color: #151875;
  font-size: 0.9rem;
  font-weight: 550;
  padding-left: 10px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.productthumb_row2_outer_col3_row2 {
  padding-bottom: 15px;
}

.productthumb_row2_outer_col3_row2 span {
  color: #151875;
  font-size: 0.9rem;
  font-weight: 550;
}

.productthumb_row2_outer_col3_row2 s {
  /* padding-top: 10px; */
  padding-left: 20px;
  color: #fb2e86;
  font-size: 0.9rem;
  font-weight: 550;
}

.productthumb_row2_outer_col3_label1 {
  color: #151875;
  padding-bottom: 10px;
  font-size: 0.9rem;
  font-weight: 550;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.productthumb_row2_outer_col3_label2 {
  /* color: #151875;  */
  color: #a9acc6;
  font-size: 0.8rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  /* font-size: 0.8rem; */
  font-weight: 500;
}
.productthumb_whatsapp{
  padding-top: 5px;
  margin-left: 10px;
}
.productthumb_row2_outer_col3_row3 {
  display: flex;
  padding-top: 30px;
  padding-bottom: 25px;
  padding-left: 50px;
  align-items: center;
  column-gap: 20px;
  /* align-content: center;
    align-items: center; */
  /* justify-content: center; */
}

.productthumb_row2_outer_col3_row3 img {
  color: #151875;
  /* padding-left: 20px; */
}

.productthumb_row2_outer_col3_row3_like_outer {
  margin-left: 15px;
  justify-content: center;
}

.productthumb_row2_outer_col3_row3_like {
  display: flex;
  justify-content: center;
  align-items: center;
}

.productthumb_row2_outer_col3_row3 button {
  padding-right: 30px;
  color: #151875;
  font-size: 0.9rem;
  font-weight: 550;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  border: none;
  outline: none;
  padding: 8px 10px;
  /* text-decoration-line: underline; */
  cursor: pointer;
}
.productthumb_row2_outer_col3_row4{
  width: 100%;
  display: flex;
  justify-content: right;
  text-decoration: underline;
}

.productthumb_row2_outer_col3_label3 {
  color: #151875;
  font-size: 0.85rem;
  padding-bottom: 15px;
  font-weight: 550;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.productthumb_row2_outer_col3_label3_span1{
  padding-left: 60px;
  padding-right: 20px;
}
.productthumb_row2_outer_col3_label3_span2{
  padding-left: 12px;
  padding-right: 20px;
}
.productthumb_row2_outer_col3_label3_span3{
  padding-left: 12px;
  padding-right: 20px;
}
.productthumb_row2_outer_col3_label3_span4{
  padding-left: 25px;
  padding-right: 20px;
}
.productthumb_row2_outer_col3_label3_span5{
  padding-left: 68px;
  padding-right: 20px;
}
.productthumb_row2_outer_col3_label4 {
  color: #151875;
  font-size: 0.9rem;
  padding-bottom: 10px;
  font-weight: 550;
}

.productthumb_row2_outer_col3_row4 {
  display: flex;
  padding-bottom: 10px;
}

.productthumb_row2_outer_col3_row4 label {
  color: #151875;
  font-size: 0.9rem;
  padding-right: 20px;
  font-weight: 550;
}

.productthumbcircle {
  display: flex;
  width: 31px;
  height: 31px;
  background-color: #e9e9f4;
  color: #151875;
  border-radius: 50%;
  /* margin-right: 5px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-left: 6px;
  padding-bottom: 3px; */
}
.productthumbcircle_click {
  display: flex;
  width: 31px;
  height: 31px;
  background-color: pink;
  color: white;
  border-radius: 50%;
  /* margin-right: 5px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-left: 6px;
  padding-bottom: 3px; */
}


.productthumb_row2_outer_inner {
  display: flex;
}

.productthumb_row2_outer_col2:hover img {
  transform: scale(2, 2);
}

.productthumb_row2_outer_col1_img1:hover img {
  transform: scale(5, 5);
}

@media only screen and (max-width:1500px){
  .productthumb_row2_outer{
    width: 95%;
  }
}

@media only screen and (max-width: 900px) {
  .productthumb_row2_outer {
    width: 95%;
  }
  .productthumb_row2_outer_col2 img{
    width: 250px;
    height: 320px;
  }
  .ProductDescription_body{
    padding-left: 0px;
    padding-right: 0px;
  }
  .productthumb_row2_outer_col2 img{
    width: 200px;
    height: 270px;
  }
  .productthumb_row2_outer_col1_img1 img{
    width: 100px;
    height: 95px;
  }
  .productthumb_row2_outer_col3{
    padding-top: 20px;
  }
  .productthumb_row2_outer_col3 h5{
    padding-bottom: 0px;
  }
  .productthumb_row2_outer_col3_row3{
    padding-top: 10px;
  }
}

@media only screen and (max-width:650px){
  .ProductDescription_body{
    padding-right:0px;
  }
  .productthumb_row2_outer{
    display: flex;
    flex-direction: column;
}
.productthumb_row2_outer_inner{
  display: flex;
  justify-content: center;
}
.productthumb_row2_outer_col3{
  display: flex;
  justify-content: center;
}
}


@media only screen and (max-width: 500px) {
  .productthumb_row2_outer {
    display: flex;
    flex-direction: column;
    width: 95%;
  }

  .productthumb_row2_outer_inner {
    display: flex;
    justify-content: center;
  }
  /* .productthumb_row2_outer_col3_row3 img{
    padding-left: 0px;
  } */
  /* .productthumbcircle{
    display: flex;
    justify-content: center;
  } */
  /* .productthumb_row2_outer_col1 {
    display: block;
  } */
  .productthumb_row2_outer_col1 img {
    width: 90px;
    height: 90px;
    /* border-radius: 10; */
  }

  .productthumb_row2_outer_col2 {
    display: contents;
  }

  .productthumb_row2_outer_col2 img {
    width: 300px;
    height: 300px;
  }

  .productthumb_row2_outer_col3 {
    display: flex;
  }

  .productthumb_row2 {}
  .productthumb_row2_outer_col2:hover img {
    transform: scale(1.5, 1.5);
    position: absolute;
    margin-top:-20px;
  }
  .productthumb_row2_outer_col1_img1:hover .productthumb_row2_outer_col1_img1_in1{
margin-top: -25px;
  }

  .productthumb_row2_outer_col1_img1:hover .productthumb_row2_outer_col1_img1_in2{
    margin-top: -150px;
      }
  
  .productthumb_row2_outer_col1_img1:hover img {
    transform: scale(3, 3);
    position: absolute;
    margin-left: 70px;
  }
  
}
@media only screen and (max-width:380px){
  .productthumb_row2_outer_col2 img{
    width: 200px;
    height: 200px;
  }
  .productthumb_row2_outer_col1_img1 img{
    /* width:; */
  }
  .productthumb_row2{
    width: 98%;
  }
}


@media only screen and (max-width: 350px) {
  .productthumb_row2_outer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .productthumb_row2_outer_inner {}

  /* .productthumb_row2_outer_col1 {
    display: block;
  } */
  .productthumb_row2_outer_col1 img {
    width: 60px;
    height: 60px;
  }

  .productthumb_row2_outer_col2 {
    display: contents;
  }

  .productthumb_row2_outer_col2 img {
    width: 200px;
    height: 200px;
  }

  .productthumb_row2_outer_col3 {
    display: flex;
  }

  .productthumb_row2 {}
}



/********************toast/snackbar**************/

/* The snackbar - position it at the bottom and in the middle of the screen */

#snackbar {
  visibility: hidden;
  /* Hidden by default. Visible on click */
  min-width: 250px;
  /* Set a default minimum width */
  margin-left: -125px;
  /* Divide value of min-width by 2 */
  background-color: #333;
  /* Black background color */
  color: #fff;
  /* White text color */
  text-align: center;
  /* Centered text */
  border-radius: 2px;
  /* Rounded borders */
  padding: 16px;
  /* Padding */
  position: fixed;
  /* Sit on top of the screen */
  z-index: 1;
  /* Add a z-index if needed */
  left: 50%;
  /* Center the snackbar */
  bottom: 30px;
  /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible;
  /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}