.mobmenubar {
    background-color: white;
    /* padding: 0 5%; */
    width: 98%;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
    color: black;
    margin: 0px;
    padding: 0px;
    /* position: absolute; */
    margin-top: -160px;
    /* position: sticky; */
    /* bottom: 0px; */
    z-index: 1;
  }
  
  .mobmenubar_ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .mobmenubar_li {
    list-style-type: none;
    width: 100%;
    font-weight: bold;
    color: rgb(12, 11, 11);
    /* border-bottom: 1px solid black; */
   padding: 5px; 
  }
  .mobmenubar_li_cat{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
       padding: 5px 10px; 
       color: black;
  }
  
  /* .mobmenubar_li:hover {
    color: #fb2e86;
    display: flex;
    border-bottom: 3px solid #fb2e86;
  } */
  
  .mobmenubar_popup {
    position: absolute;
    display: flex;
    background-color: white;
    width: 100%;
    margin-left: 2px;
    top: 0;
    border: 1px solid gray;
    margin-top: -325px;
    z-index: 1;
    /* overflow-y: scroll; */
  }
  .mobmenubar_popup_popup {
    /* position: absolute; */
    display: flex;
    background-color: white;
    width: 101%;
    margin-left: 2px;
    top: 0;
    border: 1px solid gray;
    margin-top: -325px;
    z-index: 1;
    /* overflow-y: scroll; */
    /* height: 74vh; */
  }
  
  .mobmenubar_title {
    width: 20%;
    color: black;
    /* padding: 10px; */
    /* justify-content: center;
    display: flex; */
    /* flex-direction: row; */
  }
  .mobmenubar_li_cat_arrow{
    width: 80%;
    display: flex;
    justify-content: right;

      }
  .mobmenubar_li_cat_arrow_inn{
   
  }
  .mobmenubar_li:hover~.menubar_popup {
    display: flex;
  }
  
  .mobmenubar_title:hover~.menubar_popup {
    display: flex;
  }
  
  .mobmenubar_title_goldrate {
    display: flex;
    flex-direction: column;
    margin-top: 8.5px;
    z-index: 1;
    /* position: absolute; */
    background-color: #f4579b;
    /* width: 50%; */
    padding-top: 20px;
    padding-bottom: 30px;
    border: 1px solid silver;
    /* margin-left: -2.5%; */
    row-gap: 20px;
    justify-content: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: .85rem;
  
  }
  
  .mobmenubar_title_goldrate label {
    /* display: flex; */
    /* margin-left: 50px; */
    display: flex;
    justify-content: center;
  }
  
  /* .mobmenubar_title:hover~.menubar_title_goldrate {
    display: flex;
    flex-direction: column;
    z-index: 2px;
    position: absolute;
    margin-top: 35.7px;
    margin-left: 30px;
    color: white;
    justify-content: center;
  } */
  
  .mobmenubar_title_goldrate_row1 {
    display: flex;
    justify-content: center;
    border-bottom: 2px solid rgb(207, 201, 168);
    width: 85%;
    margin-left: 15px;
    padding-bottom: 10px;
  }
  
  .mobmenubar_title_goldrate_row1 span {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .mobmenubar_popup_left {
    width: 100%;
    text-align: left;
    overflow-y: scroll;
  }
  
  .mobmenubar_popup_left ul {
    padding: 0;
    display: flex;
    justify-content: left;
    flex-direction: column;
    margin: 0;
    overflow-y: scroll;
    /* margin-top: 160px; */
  }
  .mobmenubar_popup_left_pupup{
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: left;
    flex-direction: column;
    margin: 0;
    overflow-y: scroll;
    margin-top: 160px;
  }

  
  .mobmenubar_popup_left_li {
    /* overflow-y: scroll; */
    list-style-type: none;
  }
  .mobmenubar_popup_left_li_inner{
    border-bottom: 1px solid rgb(210, 207, 207);
    padding: 10px;
    background-color: white;
    justify-content: space-between;
    color: black;
    align-items: center;
    font-weight: normal;
    display: flex;
    /* margin-left: -30px; */
  }
  .mobmenubar_popup_left_li_inner_popup{
    border-bottom: 1px solid rgb(210, 207, 207);
    padding: 10px;
    background-color: white;
    justify-content: space-between;
    color: black;
    align-items: center;
    font-weight: normal;
    display: flex;
    margin-left: -30px;
  }
  .mobmenubar_popup_left_category_popup{
    border-bottom: 1px solid rgb(210, 207, 207);
    padding: 10px;
    background-color: white;
    color: black;
    align-items: center;
    font-weight: normal;
    display: flex;
    margin-left: -45px;
  }
  .mobmenubar_popup_left_li_inner1{
    border-bottom: 1px solid rgb(210, 207, 207);
    /* position: absolute; */
    /* z-index: 2; */
    width: 100%;
  }
  .mobmenubar_popup_left_category{
    border-bottom: 1px solid rgb(210, 207, 207);
    padding: 10px;
    background-color: white;
    color: black;
    align-items: center;
    font-weight: normal;
    display: flex;
    margin-left: -10px;
  }
  .mobmenubar_popup_right_one li{
    border-bottom: .1px solid rgb(237, 233, 233);
    margin-left: 15px;
  }
  .mobmenubar_popup_left_category label{
    color: #f4579b;
    font-weight: 550;
    font-size: .95rem;
  }
  .mobmenubar_popup_left_category_popup label{
    color: #f4579b;
    font-weight: 550;
    font-size: .95rem;
  }
  .mobmenubar_popup_left_title {
    display: flex;
    flex-direction: column;
  }
  
  .mobmenubar_popup_left_title_bold {
    display: flex;
    flex-direction: column;
    font-weight: bold;
  }
  
  .mobmenubar_popup_left_img img {
    width: 30px;
    height: 30px;
  }
  
  .mobmenubar_popup_right_one_img img {
    width: 30px;
    height: 30px;
  }
  
  
  .mobmenubar_popup_left_li:hover {
    font-weight: bold;
  }
  
  .mobmenubar_popup_right {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* margin-left: -2.5px; */
    /* position: absolute; */
    background-color: white;
    margin-top: 15px;
    /* z-index: 5; */
    /* justify-content: right; */
  }
  .mobmenubar_popup_right_subcat{
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px solid rgb(243, 235, 235);
  }
  .mobmenubar_popup_right_subcat label{
    color: #f4579b;
    font-weight: 550;
    font-size: .95rem;
  }
  
  .mobmenubar_popup_right h3 {
    color: rgb(161, 160, 160);
    font-size: 0.9rem;
  }
  
  .mobmenubar_popup_right ul {
    padding: 0;
    margin: 0;
  }
  
  .mobmenubar_popup_right li {
    color: black;
    padding: 10px 0;
    font-weight: normal;
    display: flex;
    align-items: center;
  }
  
  .mobmenubar_popup_right li:hover {
    font-weight: bold;
  }
  
  .mobmenubar_popup_icon {
    margin-right: 10px;
  }
  .mobmenubar_popup_right_outer{
    /* overflow-y: scroll;
    height: 25vh; */
    /* position: absolute; */
    background-color: white;
    width: 97%;
    margin-top: 40px;
    /* z-index: 5; */
    /* margin-left: -25px; */
  }
  .mobmenubar_popup_right_submenu{
    /* overflow-y: scroll; */
    /* height: 74vh; */
  }
@media only screen and (max-width:415px){
  /* .mobmenubar_popup_right {
    margin-top: -80px
  } */
  /* .mobmenubar_popup_right_submenu{
    overflow-y: scroll;
    height: 58vh;
  } */

}

@media only screen and (max-width:395px){
  .mobmenubar_popup_right {
    margin-top: -45px
  }
  .mobmenubar_popup_right_submenu{
    overflow-y: scroll;
    /* height: 58vh; */
  }
}

  @media only screen and (max-width:380px){
    .mobmenubar{
      width:95%;
    }
    .mobmenubar_popup_right {
      margin-top: -45px
    }
    .mobmenubar_popup_right_submenu{
      overflow-y: scroll;
      height: 74vh;
    }
  }
  @media only screen and (max-width:360px){
    .mobmenubar_popup_right {
      margin-top: -50px
    }
    .mobmenubar_popup_right_submenu{
      overflow-y: scroll;
      height: 67vh;
    }
  
  }

  @media only screen and (max-width:280px){
    .mobmenubar_popup_right {
      margin-top: -35px
    }
    .mobmenubar_popup_right_submenu{
      overflow-y: scroll;
      height: 74vh;
    }
  
  }
  
  
  
  /* @media only screen and (max-width:1500px){
    .mobmenubar_title_goldrate {
      width: 25%;
      margin-left: -300px;
    }
     .mobmenubar_title:hover~.menubar_title_goldrate {
      display: flex;
      flex-direction: column;
      z-index: 2px;
      position: absolute;
      margin-top: 35.7px;
      margin-left: -30px;
      color: white;
      justify-content: center;
    }
   
  }
  
  @media only screen and (max-width: 900px) {
    .mobmenubar {
      width: 90%;
    }
  
    .mobmenubar_popup {
      width: 99.6%;
    }
  
    .mobmenubar_title_goldrate {
      width: 30%;
      margin-left: -150px;
    }
    .mobmenubar_title:hover~.menubar_title_goldrate {
      display: flex;
      flex-direction: column;
      z-index: 2px;
      position: absolute;
      margin-top: 35.7px;
      margin-left: -55px;
      color: white;
      justify-content: center;
    }
    
  } */
/*   
  @media only screen and (max-width: 500px) {
  
    
    .mobmenubar_ul {
      display: flex;
      flex-direction: column;
    }
  
    .mobmenubar_title_goldrate {
      width: 98%;
      margin-left: -14.7px;
    }
  
    .mobmenubar_popup_right {
      margin-left: 40px;
    }
  
    .mobmenubar_popup {
      width: 99.5%;
    }
  }
  
  
  @media only screen and (max-width: 350px) {
    .mobmenubar_ul {
      display: flex;
      flex-direction: column;
    }
  
    .mobmenubar_title_goldrate {
      width: 98%;
      margin-left: -14.7px;
    }
  
    .mobmenubar_popup_right {
      margin-left: 40px;
    }
  
    
  } */
  
  
  