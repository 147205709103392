.filterbar{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-top: 1px solid rgb(221, 215, 215);
    /* margin-top: 15%; */
    position: sticky;
    bottom: 0;
    width: 93.6%;
}
.filterbar_row{
    display: flex;
    align-items: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.filterbar_row1{
    display: flex;
    align-items: center;
    justify-content: center;
}
.filterbar_row3{
    display: flex;
    align-items: center;
    justify-content: center;
    /* column-gap: 20px; */
}
.filterbar_row3 label{
    padding-left: 5px;
}
.filterbar_row label{
    font-size: .85rem;
    font-weight: 550;
}
.filterbar_row_boarder1{
    display: flex;
    width: 1px;
    height: 20px;
    background-color: silver;
    margin-right: 1%;
}
.filterbar_row_boarder2{
    width: .5px;
    height: 20px;
    background-color: silver;
    margin-left: 1.1%;
}

@media only screen and (max-width:290px){
    .mobmenubar{
        width: 96%;
    }
}