.tp-r1 {
  text-align: center;
  /* font-size: 33px; */
  /* color: blue; */
  padding-bottom: 33px;
}
.trendingproduct_loader{
  position: absolute;
  margin-left: 48%;
  margin-top: 60px;
}

.tp-r1 label {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 2rem;
  font-weight: 650;
  color: #151875;
}

.tp-r2 {
  font-weight: 650;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
}

.tp-dollor {
  display: flex;
  flex-direction: row;
}

.tp-dp {
  padding-right: 15px;
  font-size: 0.9rem;
}

.tp-r3-c3-c177555 {
  color: #151875;
  font-size: 0.85rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.tp-r2-cantlvr-chair-1 {
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  width: 18%;
  padding-left: 22px;
  color: #151875;
}

.tp-r2-cantlvr-chair-2 {
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  width: 18%;
  padding-left: 22px;
  color: #151875;
}

.tp-r2-cantlvr-chair-3 {
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  width: 17%;
  padding-left: 22px;
  padding-right: 71px;
  color: #151875;
}

.tp-r2-cantlvr-chair-0 {
  display: flex;
  flex-direction: column;
  background-color: rgb(18, 155, 52);
  width: 18%;
  /* padding-left: 19px; */
}

.tp-r2-lbs {
  padding-left: 44px;
  /* color: rgb(189, 189, 189); */
  /* padding-top: 11px; */
}

.tp-ash-lbl {
  color: rgba(21, 24, 117, 0.3);
  font-size: 0.9rem;
}

.tp-r2-lbs {
  background-color: rgb(255, 255, 255);
  /* width: 15%; */
  /* padding-top: 11px; */
  /* padding-bottom: 11px; */
}

.tp-r2 {
  display: flex;
  flex-direction: row;
  padding-left: 199px;
}

.tp-r3-c1 {
  display: flex;
  flex-direction: column;
  padding-right: 22px;
  background-color: #fff6fb;
  box-shadow: 0px 8px 40px rgba(49, 32, 138, 0.05);
}

.tp-r3-c2 {
  display: flex;
  flex-direction: column;
}

.tp-r2-cantlvr-chair-11 {
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
}

.tp-lbs-1 {
  color: #151875;
  padding-left: 11px;
  padding-top: 22px;
  font-size: 1.1rem;
  font-weight: 500;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  padding-bottom: 10px;
}

.tp-shopnow {
  color: red;
  padding-left: 14px;
  text-decoration: underline;
  padding-bottom: 10px;
}

.tp-r3-clk {
  padding-left: 88px;
}

.tp-r3-c1 {
  background-color: rgb(255, 255, 255);
  width: 22%;
}

.tp-r3-c2 {
  background-color: rgb(235, 224, 224);
  width: 24%;
}

.tp-r3-cover {
  display: flex;
  flex-direction: row;
}

.tp-r3-clk-2 {
  padding-left: 22px;
}

.tp-r3-cover {
  padding-left: 211px;
  padding-top: 44px;
}

.tp-r3-lastchair-1 {
  display: flex;
  flex-direction: column;
  padding-left: 11px;
  color: #151875;
  font-weight: 500;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.83rem;
}

.tp-r3-c3-c1 {
  display: flex;
  flex-direction: row;
  padding-bottom: 33px;
}

.tp-r3-5655 {
  background-color: rgb(210, 223, 223);
  padding-left: 11px;
  padding-right: 11px;
}

.tp-r3-c3-cover {
  padding-left: 22px;
  /* padding-bottom: 35px; */
}


.trendingproducys_header {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 650;
  font-size: 2rem;
  color: #fb2e86;
  text-align: center;
}

.trendingproducts_cont {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 50px;
  padding-top: 50px;
}
.trendingproducts_list_img{
  background-color: #fcf8f8;
  display: flex;
  align-items: center;
  padding: 15px;
}

.trendingproducts_list_img img {
  width: 230px;
  height: 230px;
}

.trendingproducts_list_label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 10px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: .9rem;
  font-weight: 500;
  color: #fb2e86;
}
.trendingproducts_list_label_label1{
  display: flex;
  flex-direction: column;
}
.trendingproducts_list_label_label1_lab{
  padding-top: 5px;
  font-size: .8rem;
}
.trendingproducts_list_label_price{
  font-size: .8rem;
  color: #151875;
}

/* @media only screen and (max-width: 500px) {
  .trendingproducts {
    padding-top: 50px;
  }

  .trendingproducts_cont {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .trendingproducts_list_label{
    display: contents;
  }
  .tp-r2 {
    display: contents;
  }

  .tp-r3-cover {
    display: contents;
  }
} */


.trendingproducys_header {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 650;
  font-size: 2rem;
  color: #fb2e86;
  text-align: center;
}

.trendingproducts_cont {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 50px;
  padding-top: 50px;
}
.singletrendingproduct{
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}

/* @media only screen and (max-width: 500px) {
  .tp-r2 {
    display: contents;
  }

  .tp-r3-cover {
    display: contents;
  }
} */


/* @media only screen and (max-width: 500px) {
  .trendingproduct_loader{
    margin-top: 100px;
  }
  .tp-r2 {
    display: contents;
  }

  .tp-r3-cover {
    display: contents;
  }
}
 */
@media only screen and (max-width:1200px){
  .singletrendingproduct{
    /* width: 25%; */
    /* margin-left: -50px; */
    /* display: grid;
    grid-template-columns: repeat(2,1fr); */
  }
  .trendingproducts_list_img img{
    width: 150px;
    height: 150px;
  }
  .trendingproducts_list{
    margin-bottom: 20px;
  }
}

@media only screen and (max-width:900px){
  .trendingproducts_list_img img{
    width: 100px;
    height: 100px;
  }
  .trendingproducts_list_img{
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width:750px){
.trendingproducts_list_label_label1{
  font-size: .8rem;
}
.trendingproducts_list_label_label1_lab{
  font-size: .6rem;
}
.trendingproducts_list_label_price{
  font-size: .7rem;
}
}

@media only screen and (max-width:600px){
  .trendingproducts_list_img img{
    width: 70px;
    height: 70px;
  }
  .trendingproducts_list_label_price{
    font-size: .6rem;
  }
}
@media only screen and (max-width:550px){
  .trendingproducts_list_label_label1{
    font-size: .7rem;
  }
  .trendingproducts_list_label_label1_lab{
    font-size: .5rem;
  }
  .trendingproducts_list_label_price{
    font-size: .6rem;
  }
}

@media only screen and (max-width: 500px) {
  .trendingproducts {
    padding-top: 50px;
    margin-bottom: 0px;
  }

  .trendingproducts_cont {
    display: flex;
    width: 100%;
    /* background-color: yellow; */
    /* flex-direction: column; */
    justify-content: center;
    margin-left: -35px;
    /* padding-left: 20px; */
  }
  .singletrendingproduct{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* text-align: center; */
    width: 50%;

    /* background-color: gainsboro; */
  }

  .trendingproducts_list_label {
    display: flex;
    flex-direction: row;
  }
  .trendingproducts_list{
    margin-bottom: 50px;
    width: 60%;
  }
  .trendingproducts_list_img{
    width: 176%;
   
  }
  .trendingproducts_list_label{
    width: 176%;
  }
  .trendingproduct_loader{
    margin-top:100px ;
  }
  .trendingproducts_list_img img {
    width: 200px;
    height: 200px;
  }
  
  .trendingproducts_list_label {
    font-size: .9rem;
  }
  .trendingproducts_list_label_label1{
  }
  .trendingproducts_list_label_label1_lab{
    font-size: .8rem;
  }
  .trendingproducts_list_label_price{
    font-size: .8rem;
  }
  .LatestProducts_bottom_imgchair_lefttext_row2{
    width: 65%;
  }
  .LatestProducts_bottom_imgchair_righttext_row2{
    width: 35%;
  }
  
}


@media only screen and (max-width: 350px) {
  .trendingproducts {
    padding-top: 50px;
  }

  .trendingproducts_cont {
    display: flex;
    flex-direction: column;
    /* text-align: center; */
    margin-left: 25px;
  }

  .trendingproducts_list_label {
    display: flex;
  }
  .trendingproducts_list_img img{
    width: 150px;
  }
  .singletrendingproduct{
    width: 70%;
  }
}

@media only screen and (max-width:290px){
  .trendingproducts{
    /* background-color: green; */
  }
  .trendingproducts_cont{
    width: 90%;
    /* background-color: yellow; */
  }
}

/* 
@media only screen and (max-width:900px) {
  .trendingproducts_cont{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;

  }
  .trendingproducts{
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 40px;
  }
  
} */