.BrandList_image {
  display: none;
  flex-direction: column;
  background-color: white;
  padding-top: 50px;
  align-items: center;
}

.BrandList_image img {
  width: fit-content;
  height: fit-content;
}

@media only screen and (max-width: 500px) {
  .BrandList_image {
    /* width: 70%; */
  }

  .BrandList_image img {
    width: 250px;
  }

}

@media only screen and (max-width: 350px) {
  .BrandList_image img {
    width: 312px;
  }
}

@media only screen and (max-width: 900px) {
  .BrandList_image {
    width: 99.5%;
    /* background-color: yellow; */
  }

  .BrandList_image img {
    width: 90%;
  }

}