.Discountitem_row1 {
  background-color: white;
  padding-top: 50px;
}

.Discountitem_row1_line1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 50px;
  /* padding-bottom: 30px; */
}

.Discountitem_row1_line1 label {
  font-size: 2.2rem;
  font-weight: 650;
  color: #fb2e86;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  /* font-weight: bold; */
}

.Discountitem_row1_line2 {
  display: flex;
  flex-direction: row;
  /* padding-left: 600px;
    padding-right: 600px; */
  padding-top: 20px;
  /* padding-bottom: 10px; */
  justify-content: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
}

.Discountitem_row1_line2_b2,
.Discountitem_row1_line2_b3 {
  padding-left: 20px;
}

.Discountitem_row1_line2_b2 button,
.Discountitem_row1_line2_b3 button {
  border: none;
  color: #151875;
  background-color: white;
  font-size: 0.9rem;
  letter-spacing: 1px;
}

.Discountitem_row1_line2_b1 button {
  border: none;
  color: #151875;
  background-color: white;
  font-size: 0.9rem;
  cursor: pointer;
  letter-spacing: 0.9px;
  font-weight: 500;
}

.Discountitem_row1_line2_b1 button:hover {
  color: #fb4997;
  text-decoration: underline;
}

.Discountitem_row2 {
  background-color: white;
  display: flex;
  flex-direction: row;
}

.Discountitem_row2_col1 {
  display: flex;
  flex-direction: column;
  width: 60%;
  padding-left: 250px;
  /* align-items: center; */
  justify-content: center;
}

.Discountitem_row2_col2 {
  display: flex;
  flex-direction: column;
  width: 40%;
  align-items: center;
  padding-right: 100px;
}

.Discountitem_row2_col2 img {
  width: 300px;
  height: 400px;
  padding-right: 87px;
}

.Discountitem_row2_col1_l1 label {
  font-size: 1.5rem;
  font-weight: 650;
  color: #fb2e86;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  /* font-weight: bold; */
  letter-spacing: 1px;
}

.Discountitem_row2_col1_l2 label {
  font-size: 1.1rem;
  color: #fb2e86;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  letter-spacing: 1px;
}

.Discountitem_row2_col1_l3 label {
  color: #151875;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: .9rem;
  font-weight: 500;
  /* letter-spacing: 1px; */
}

.Discountitem_row2_col1_l4 {
  display: flex;
  flex-direction: row;
  color: #151875;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: .9rem;
  font-weight: 500;
  /* letter-spacing: 1px; */
}

.Discountitem_row2_col1_l5 button {
  border: none;
  color: white;
  background-color: #fb4997;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  font-size: 0.9rem;
  padding: 15px 10%;
  border-radius: 2px;
  letter-spacing: 1px;
}

.Discountitem_row2_col1_l1,
.Discountitem_row2_col1_l2,
.Discountitem_row2_col1_l3,
.Discountitem_row2_col1_l4,
.Discountitem_row2_col1_l5 {
  padding-bottom: 30px;
}
.Discountitem_row2_col1_l4 img{
  width: 12px;
}

.Discountitem_row2_col1_l4_c1 {
  padding-right: 15px;
}

.Discountitem_row2_col1_l4_c1_l1,
.Discountitem_row2_col1_l4_c2_l1 {
  padding-bottom: 10px;
}

.Discountitem_row2_col1_l3 {
  line-height: 30px;
}

.Discountitem_row2_col2_mobview{
  display: none;
}



@media only screen and (max-width:500px) {
  .Discountitem_row1_line1{
    padding-top: 0px;
  }
  /* .Discountitem_row2_col2 img {
    width: 100px;
  } */
  /* .Discountitem_row2_col2{
    display: none;
  } */

  .Discountitem_row1 {
    /* width: 100%; */
    background-color: white;
    padding-top: 0px;
  }
  

  .Discountitem_row2 {
    display: flex;
    justify-content: center;
    padding-top: 40px;
  }

  .Discountitem_row2_col1 {
    display: flex;
    padding-left: 98px;
  }

  .Discountitem_row2_col2 {
    display: none;
    padding-right: 0px;
    /* width: 50%; */
  }
  .Discountitem_row2_col2_mobview{
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .Discountitem_row2_col2_mobview img{
    width: 350px;
    height: 350px;
    padding-top:10px ;
  }
.Discountitem_row2_col1_l5{
  display: flex;
  justify-content: center;
}

  .Discountitem_row2_col1 {
    padding-left: 0px;
    margin-left: 10px;
  }

  .Discountitem_row2_col1_l1 label {
    font-size: .6rem;
  }

  /* .Discountitem_row2_col1_l2{
    font-size: .8rem;
  } */
  .Discountitem_row2_col1_l3 label {
    font-size: .7rem;
  }

  .Discountitem_row2_col2 img {
    width: 250px;
    height: 400px;
    padding-right: 0px;
  }

  .Discountitem_row2 {
    padding-left: 0px;
    /* margin-left: -30px; */
  }

  .Discountitem_row2_col1_l4 label {
    font-size: .7rem;
  }

  /* .Discountitem_row2_col1_l5{
    padding-left: 40px;
  }
   */
}

/* @media only screen and (max-width: 350px) {
  .Discountitem_row2_col2 img {
    width: 44%;
  }
  .Discountitem_row2 {
    display: flex;
    justify-content: center;
    padding-top: 40px;
    gap: 10px;
  }
  .Discountitem_row2_col1 {
    display: flex;
    padding-left: 56px;
  }
  .Discountitem_row2_col2 {
    display: contents;
  }
} */

@media only screen and (max-width: 350px) {
  .Discountitem_row2_col2 img {
    width: 171px;
  }

  .Discountitem_row2 {
    display: flex;
    justify-content: center;
    padding-top: 40px;
    gap: 10px;
    width: 95%;
  }

  .Discountitem_row1 {
    width: 97%;
  }

  .Discountitem_row2_col1 {
    display: flex;
    /* width: 175px; */
    padding-left: 56px;
  }

  .Discountitem_row2_col2 {
    display: contents;
  }

  .Discountitem_row2_col1_l1 label {
    font-size: .6rem;
  }

  .Discountitem_row2_col1_l2 label {
    font-size: medium;
  }

  .Discountitem_row2_col1_l3 label {
    font-size: .6rem;
  }

  .Discountitem_row2_col1_l4 {
    font-size: x-small;
  }

}

/* @media only screen and (max-width: 900px) {
  .Discountitem_row1 {
    width: 99%;
  }
  .Discountitem_row2{
    width: 99%;
    display: flex;
    margin-left: 0px;
  }
  .Discountitem_row2_col1{
    margin-left:-32%;
    display: flex;
    width: 60%;
  }
  .Discountitem_row2_col2{
    width: 40%;
    margin-right: -100px;
    display: flex;
    justify-content: center;

  }
  .Discountitem_row2_col2 img{
    padding-right: 0px;
  }
} */