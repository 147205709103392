.componentlatestproducts {
    margin-right: 15px;
}

.Homepage_menubar_mobview {
    display: none;
}

.footer_row1_col2_hide {
    display: none;
}
.componentofferlist{
    margin-left: -100px;
    background-color: #FFFF;
}
.homepage_latestblog{
    display: none;
}
.hompage_outer{
    display: none;
}
.componentinbetweenbanner2{
    margin-top: 60px;
}

.componentfeaturedlist{
    background-color: white;
}
.Homepage_header{
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #c90053cf;
}
.homepage_whatsapp{
    position: sticky;
    bottom: 10px;
    display: block;
    margin-top: -100px;
}
@media only screen and (max-width: 500px) {
    .Homepage_menubar_mobview {
        display: flex;
        position: absolute;
        margin-top: -220px;
        width: 100%;
    }

    .Homepage_menubar {
        display: none;
    }
    .componentfeaturedlist{
        width: 100%;
        background-color: white;
        /* margin-left: 55px; */
    }

    .componentlatestproducts{
        /* margin-right: 30px; */
        width: 100%;
    }
    /* .hompage_outer{
        width: 100%;
    } */
}

@media only screen and (max-width: 350px) {
    .Homepage_menubar_mobview {
        display: flex;
        position: absolute;
        margin-top: -240px;
        width: 100%;
    }

    .Homepage_menubar {
        display: none;
    }
}
@media only screen and (max-width:290px){
    .componenttrendingproducts{
        /* width: 90%; */
    }
}

@media only screen and (max-width: 900px) {
    /* .componentofferlist {
        margin-left: -15px;
        width: 95%;
    } */

    /* .componentfeaturedlist{
        width: 70%;
    } */

    /* .hompage_outer {
        width: 87.6%;
    } */
}