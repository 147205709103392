@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');


/*-----------------------------InBetweenBanner1------------------------*/
.InBetweenBanner1_outer{
    background-color: #F1F0FF;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 80px;
}
.InBetweenBanner1{
    width: fit-content;
    height: fit-content;
}

.InBetweenBanner1_container {

    width: 80%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    justify-content: center;
    margin-left: 40px;
    align-items: center;
    padding: 20px;


}

.InBetweenBanner1_leftimg_col1 {
    /* width:558 px;
    height:550 px; */
    width: 38%;
    padding: 10px;
    /* margin-top: 10px; */
    display: flex;
    justify-content: space-evenly;
    margin-right: 5px;
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
}

.InBetweenBanner1_righttext_col2 {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-bottom: 30px;
    padding-left: 100px;

}

.InBetweenBanner1_righttext_col2_row1 label {
    /* width: 100%; */
    color: #151875;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 2rem;
    font-weight: 650;
    /* line-height: 46.2px; */
    padding-bottom: 40px;
    padding-top: 10px;
    display: flex;
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
    /* text-shadow: 0px 2px 5px black; */
}

.InBetweenBanner1_righttext_col2_row2 {
    width: 100%;

    display: flex;
    color: #ACABC3;
    font-size: 5px;
    font-style: normal;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;

}

.InBetweenBanner1_circle1_col2_row2 {
    width: 5%;
    background-color: #F52B70;
    width: 11px;
    height: 11px;
    border-radius: 5px;
    align-items: center;
    margin-top: 10px;
   

    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */

}
.InBetweenBanner1_circle1_col2_row2 label{
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
}

.InBetweenBanner1_circle2_col2_row2 {
    width: 5%;
    background-color: #2B2BF5;
    width: 11px;
    height: 11px;
    margin-top: 10px;
    border-radius: 5px;
    align-items: center;

    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */

}

.InBetweenBanner1_circle3_col2_row2 {
    width: 5%;
    background-color: #2BF5CC;
    width: 11px;
    height: 11px;
    margin-top: 10px;
    border-radius: 5px;
    align-items: center;

    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
}

.InBetweenBanner1_righttext_col2_row2 label {
    width: 100%;
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
    /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    display: flex;
    justify-content: left;
    margin-bottom: 5px;
    padding-left: 10px;
    text-align: left;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500px;
    font-size: 1rem;
    /* line-height: 30.12px; */
   



}

.InBetweenBanner1_right_button_col2_row3 {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    justify-content: left;
    margin-top: 20px;
    padding: 10px;
   
}

.InBetweenBanner1_right_leftbutton_col2_row3 {
    width: 25%;
    display: flex;
}

.InBetweenBanner1_right_leftbutton_col2_row3 button {
    width: 100%;
    border: none;
    border-radius: 2px;
    color: #FFFFFF;
    background-color: #FB2E86;
    padding: 5px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
    /* box-shadow: 0px 4px 6px  rgba(0, 0, 0, 0.25); */
    /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.895); */



}

.InBetweenBanner1_right_right_textbutton_col2_row3 {
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    margin-left: 20px;
    padding: 5px;
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
    /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */

}
.InBetweenBanner1_right_right_textbutton_col2_row3_label1{
    width: 100%;
    color: #151875;
    /* padding: 5px; */
    display: flex;
    justify-content: left;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: .9rem;
    font-weight: 500;
    /* line-height: 16.41px; */

}
.InBetweenBanner1_right_right_textbutton_col2_row3_label2{
    width: 100%;
    color: #151875;
    /* padding: 5px; */
    display: flex;
    justify-content: left;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
    font-size: .9rem;
    font-weight: 500;
    /* line-height: 16.41px; */
}
@media only screen and (max-width: 500px) {
   .InBetweenBanner1_leftimg_col1 img{
    width: 100px;
   }
   .InBetweenBanner1_right_button_col2_row3{
    width: fit-content;
   }


}