.payment {
  display: flex;
  /* width: 45%; */
  /* align-items: center; */
  justify-content: center;
  padding-left: 13%;
  padding-right: 50px;
  background-color: white;
  padding-bottom:10px;
  /* box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2); */

}

.payment_outer {
  display: flex;
  justify-content: center;
  margin-top: 7%;
  background-color: #f8f8fd;
  margin-bottom: 2%;
  padding: 30px;
  padding-left: 130px;
  padding-right: 130px;
  padding-bottom: 70px;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2);
}

.payment_outer_inner label {
  color: #1d3178;
  font-size: 1.5rem;
  font-weight: 520;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.payment_outer_inner_header label{
  color: #fb2e86;
}
.payment_outer_inner_row1 {
  padding-top: 20px;
  padding-bottom: 20px;
  justify-content: right;
  display: flex;
}

.payment_outer_inner_row1 label {
  color: #929295;
  font-size: 0.9rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.payment_outer_inner_row1_label1 {
  padding-left: 30px;
  padding-right: 20px;
}

.payment_outer_inner_row1_label2 {
  padding-left: 10px;
}
/* .payment_outer_inner_row2{
    background-color: white;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
} */

.payment_outer_inner_row2_row1,
.payment_outer_inner_row2_row2,
.payment_outer_inner_row2_row3 {
  background-color: #f2f2f8;
  padding: 20px;
  /* padding-left: 20px; */
  padding-right: 60px;
  margin-bottom: 15px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.payment_outer_inner_row2_row1 label {
  font-size: 0.8rem;
  padding-left: 10px;
  font-weight: 550;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

/* .payment_outer_inner_row2_row1 input {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  border: 1px solid silver;
  appearance: none;
  outline: none;
  cursor: pointer;
  border: none;
  background-color: #d9d9d9;
} */

.payment_outer_inner_row2_row2 label {
  font-size: 0.8rem;
  padding-left: 10px;
  font-weight: 550;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

/* .payment_outer_inner_row2_row2 input {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  border: 1px solid silver;
  appearance: none;
  outline: none;
  cursor: pointer;
  border: none;
  background-color: #d9d9d9;
} */

.payment_outer_inner_row2_row3 label {
  font-size: 0.8rem;
  padding-left: 10px;
  font-weight: 550;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

/* .payment_outer_inner_row2_row3_input {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  border: 1px solid silver;
  appearance: none;
  outline: none;
  cursor: pointer;
  border: none;
  background-color: #65b05f;
} */

.payment_outer_inner_row2_row3_inner {
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-top: 10px;
}

.payment_outer_inner_row2_row3_inner label {
  padding-bottom: 10px;
  color: #acacb0;
  padding-left: 0%;
  font-size: .8rem;
  font-weight: 400;
}

.payment_outer_inner_row2_row3_date {
  display: flex;
  padding-bottom: 10px;
}

.payment_outer_inner_row2_row3_date_col {
  padding-right: 20px;
}

.payment_outer_inner_row2_row3_date_col label {
  padding-right: 10px;
}

.payment_outer_inner_row2_row3_date_col select {
  width: 35px;
  background-color: #d9d9d9;
  color:#acacb0 ;
  border: none;
  outline: none;
}


.payment_outer_inner_row2_row3_inner input {
  margin-bottom: 10px;
  padding-top: 5px;
  border: none;
  border-bottom: 2px solid #d9d9d9;
  outline: none;
  /* color: #acacb0; */
  width: 90%;
  background-color: #f2f2f8;
  font-size: 0.8rem;
  /* font-weight: 530; */
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.payment_outer_inner_row2_row3_inner ::placeholder{
    color: #acacb0;
    font-size: .75rem;
}

.payment_outer_inner_button {
  justify-content: center;
  display: flex;
  padding-top: 25px;
}

.payment_outer_inner_button button {
  padding: 10px 30px;
  color: white;
  background-color: #fb2e86;
  border: none;
  outline: none;
  border-radius: 2px;
  font-size: .8rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
