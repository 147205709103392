@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');


/* .Rating_outer{
    width: 40%;
    background-color: #F5F5F5;
    background-color: #f4f4fc;
    display: flex;
    justify-content: space-evenly;

} */
.Rating_outer_container{
    /* width: 25%; */
    display: flex;
    justify-content: left;
    /* align-items: center; */
    margin-top: 60px;
    margin-bottom: 60px;

}
.Rating_inner_container{
    /* width: 30%; */
    /* display: flex; */
    justify-content: center;
    /* align-items:left; */
    flex-direction:column;
    /* margin: 15px; */
    padding-top: 50px;
}
/* --------------row1--------- */
.Rating_inncont_row1{
    /* width: 50%; */
    background-color: #f4f4fc;
    border: none;
    border-radius: 7px;
    display: flex;
    justify-content: right;
    flex-direction:column;
    /* padding-left: 15px; */
    /* margin: 15px; */

    
  

}
.Rating_inncont_row1_row1{
    /* width: 85%; */
    display: flex;
    justify-content: left;
    padding-bottom:5px;
    border-bottom: 2px solid #E8E6F1;
    margin: 25px;
}
.Rating_inncont_row1_row1 label{
    width: 100%;
    display: flex;
    justify-content: left;
    color: #9295AA;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
    text-align: left;
    padding-bottom: 10px;
}


.Rating_inncont_row1_row2{
    width: 85%;
    display: flex;
    justify-content:center;
    margin-top: 15px;
    margin-bottom: 25px;
}
.Rating_inncont_row1_row2 img{
    /* width: 100%; */
    width: 20px;
    /* height: 50px; */
    display: flex;
    justify-content:space-around;
    margin-top: 15px;
    margin-left: 20px;
    padding-bottom: 20px;
}

/* --------------row2--------- */

.Rating_inncont_row2{
    width: 100%;
    background-color: #f4f4fc;
    border: none;
    border-radius: 7px;
    display: flex;
    justify-content: left;
    flex-direction:column;
    /* padding-left: 15px; */
    margin-top: 15px;

}
.Rating_inncont_row2_row1{
    width: 85%;
    display: flex;
    justify-content: left;
    padding-bottom:5px;
    border-bottom: 2px solid #E8E6F1;
    margin: 25px;
}
.Rating_inncont_row2_row1 label{
    width: 100%;
    display: flex;
    justify-content: left;
    color: #9295AA;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 500px;
    font-size: 22px;
    line-height: 30.12px;
    text-align: left;
    padding-bottom: 10px;

} 
.Rating_inncont_row2_row2{
    width: 85%;
    display: flex;
    justify-content:center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 20px;

}
.Rating_inncont_row2_row2 input{
    width: 100%;
    display: flex;
    justify-content:left;
    outline: none;
    background-color: white;
    border: none;
    border-radius: 8px;
    padding-bottom: 100px;
    margin-left: 20px;
    padding-right: 90px;

}

/* --------------row3---button------ */
.Rating_inncont_row3{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
    margin: 25px;

}
.Rating_inncont_row3 button{
    width: 100%;
    background-color: #FB2E86;
    display: flex;
    justify-content: center;
    text-align: center;
    font-family:"segoe ui";
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    color: white;
    border: none;
    border-radius: 7px;
    padding: 20px;
    margin-left: 170px;
}


@media only screen and (max-width: 350px) {
    
}
/********************toast/snackbar**************/

/* The snackbar - position it at the bottom and in the middle of the screen */

#snackbar {
    visibility: hidden; /* Hidden by default. Visible on click */
    min-width: 250px; /* Set a default minimum width */
    margin-left: -125px; /* Divide value of min-width by 2 */
    background-color: #333; /* Black background color */
    color: #fff; /* White text color */
    text-align: center; /* Centered text */
    border-radius: 2px; /* Rounded borders */
    padding: 16px; /* Padding */
    position: fixed; /* Sit on top of the screen */
    z-index: 1; /* Add a z-index if needed */
    left: 50%; /* Center the snackbar */
    bottom: 30px; /* 30px from the bottom */
  }
  
  /* Show the snackbar when clicking on a button (class added with JavaScript) */
  #snackbar.show {
    visibility: visible; /* Show the snackbar */
    /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
    However, delay the fade out process for 2.5 seconds */
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }
  
  /* Animations to fade the snackbar in and out */
  @-webkit-keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
  }
  
  @keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
  }
  
  @-webkit-keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }
  
  @keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }
  

