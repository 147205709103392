.footer {
  background-color: white;
  margin: 0%;
  width: 100%;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.footer_row1_col1 img {
  width: 100px;
}

/***************************Start Footer page******************************/
.footer_row1 {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  background: #c9005324;
  justify-content: center;
  padding-bottom: 50px;
  /* padding-top: 10px; */
}

.fooer_row1_row1 {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* background-color: yellow; */
  /* background-color: blue; */
}

.footer_row1_col1 {
  display: flex;
  flex-direction: column;
  width: 35%;
  /* background-color: yellow; */
  margin: 2px;
  padding: 10px;
}

.footer_row1_col2{
  display: flex;
  flex-direction: column;
  width: 24%;
  /* background-color: yellow; */
  margin: 2px;
  padding: 10px;
  padding-top: 20px;
}
.footer_row1_col2_page {
  display: flex;
  flex-direction: column;
  width: 17%;
  /* background-color: yellow; */
  margin: 2px;
  padding: 10px;
  padding-top: 20px;
}
.footer_row1_col2 label,.footer_row1_col2_page label {
  margin-left: 5px;
}


.footer_row1_col1 h1 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 2.5rem;
  color: #FB2E86;
}

.footer_row1_col2 h3,.footer_row1_col2_page h3 {
  padding-bottom: 10px;
  font-size: 1.8rem;
  color: #FB2E86;
  margin: 0px;
  margin-top: 10px;
}

.footer_row1_col1 label,
.footer_row1_col2 label,
.footer_row1_col2_page label{
  font-size: 1rem;
  color: #4e0610;
}

.footer_row1_col1_input {
  padding-bottom: 10px;
  display: flex;
}

.footer_row1_col1_input input {
  width: 170px;
  /* height: 35px; */
  padding: 10.5px;
  border-radius: 3px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border: none;
  font-size: 00.8rem;
  outline: none;
  color: #8A8FB9;
}

.footer_row1_col1_input button {
  background-color: #c90053b5;
  border: none;
  color: white;
  padding: 10px;
  width: 100px;
  border-radius: 3px;
  /* display: flex; */
}

.footer_row1_col1 label,
.footer_row1_col2 label,
.footer_row1_col2_page label {
  padding-bottom: 10px;
  font-size: 0.8rem;
}
.footer_row1_col2_span1{
  padding-left: 10px;
  padding-right: 10px;
}
.footer_row1_col2_span2{
  padding-left: 21px;
  padding-right: 10px;
}
.footer_row1_col2_span3{
  padding-left: 8px;
  padding-right: 10px;
}
.footer_row1_col2_span4{
  padding-left: 25px;
  padding-right: 10px;
}
.footer_row1_col2_span5{
  padding-left: 13px;
  padding-right: 10px;
}
.footer_row1_col2_span6{
  padding-left: 37px;
  padding-right: 10px;
}
.footer_row1_col2_span7{
  padding-left: 11px;
  padding-right: 10px;
}
.footer_row1_col2_span8{
  padding-left: 31px;
  padding-right: 10px;
}

/****************************footer div1 end**************************/

.footer_row2 {
  display: flex;
  flex-direction: row;
  margin: 0%;
  background: #f7589ab5;
}

.footer_row2_col1 {
  width: 50%;
  text-align: center;
  /* background-color: white; */
  padding: 10px;
}

.footer_row2_col1 label {
  color: white;
  font-size: 00.8rem;
}

.footer_row2_col2 {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
  padding-top: 17px;
  gap: 10px;
}

.footer_row2_col2 div {
  padding-right: 5px;
}

.footer_row1_col2 label{
  display: flex;
  align-items: center;
}

.footer_row1_col2 label span{
  padding-right: 8px;
  padding-top: 5px;
}
.footer_row1_col2 ul{
  margin-top: 0px;
}
.footer_row1_col2 ul li{
  list-style-type: none;
  display: flex;
  margin-left: -40px;
}

.footer_row1_col2_col1{
  width: 35%;
}
.footer_row1_col2_col2{
  width: 5%;
}
.footer_row1_col2_col3{
  width: 40%;
}

/******************************************************************/
/**************************footer page end************************/
/*****************************************************************/

@media only screen and (max-width: 1000px) {
  .footer_row1_col1_input input {
    width: 50%;
  }

  .fooer_row1_row1 {
    margin-left: 50px;
    padding-left: 0px;
    width: 90%;
    display: flex;
    justify-content: center;
  }

  .footer {
    width: 99.85%;
  }
.footer_row1_col1{
  /* width: 100%; */
  margin-left: -40px;
}
  
  .footer_row1_col2,
  .footer_row1_col2_page {
    width: 100%;
  }
  .footer_row1_col1{
    margin-right: 50px;
  }
  .footer_row1_col2 h3,.footer_row1_col2_page h3 {
    font-size: 1.3rem;
  }
  
  .footer_row1_col1 label,
  .footer_row1_col2 label,
  .footer_row1_col2_page label{
    font-size: .7rem;
  }
  .footer_row1_col2{
    width: 95%;
  }
  .fooer_row1_row1{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
  }
}

@media only screen and (max-width:800px){
  .footer_row1_col2 h3,.footer_row1_col2_page h3 {
    font-size: 1.3rem;
  }
  
  .footer_row1_col1 label,
  .footer_row1_col2 label,
  .footer_row1_col2_page label,
  .footer_row1_col1_input{
    font-size: .6rem;
  }
  .footer_row1_col1{
    width: 90%;
    /* margin-left: 20px; */
    padding-left: 30px;
  }
  .fooer_row1_row1{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
  }
  .footer_row1_col2{
    width: 90%;
  }

}

@media only screen and (max-width:600px){
  .fooer_row1_row1{
    margin-left: -20px;
  }
  /* .footer{
    margin-left: -20px;
  } */
}
/* @media only screen and (max-width:550px){} */

@media only screen and (max-width: 500px) {

  /* .footer{
    width: 70%;
  } */
  .fooer_row1_row1 {
    display: flex;
    flex-direction: column;
    justify-content: right;
    width: 60%;
    /* background-color: yellow; */
    /* justify-items: center; */
  }


  .footer_row2 {
    display: block;
  }

  .footer {
    display: flex;
    flex-direction: column;
  }

  .footer_row1_col2 {
    padding-top: 0px;
    display: flex;
    width: 100%;
  }

  .footer_row2 {
    display: flex;
  }

  .footer_row1 {
    display: flex;
    justify-content: center;
    margin-left: -60px;
    /* padding-top: -10px; */
    /* width: 100%; */
  }
  .footer_row1_col2 h3,.footer_row1_col2_page h3 {
    font-size: 1.8rem;
  }
  
  .footer_row1_col1 label,
  .footer_row1_col2 label,
  .footer_row1_col2_page label{
    font-size: .8rem;
  }
  .footer_row1_col1 {
    width: 100%;
    margin-left: .5px;
      margin-right: 0px;
    margin-top: -15px;
    }
  
  .footer_row1_col2{
    width: 100%;
    margin-top: -10px;
    
  }
  .footer_row1_col2_page {
    width: 100%;
    padding-top: 0px;
    margin-top: -10px;
  }
}


@media only screen and (max-width: 350px) {

  /* .footer{
    width: 70%;
  } */
  .fooer_row1_row1 {
    display: block;
  }

  .footer_row2 {
    display: block;
  }

  .footer {
    display: flex;
    flex-direction: column;
  }

  .footer_row1_col2 {
    /* display: none; */
    padding-top: 0px;
    display: inline-grid;
    width: min-content;
  }

  .footer_row2 {
    display: flex;
  }

  .footer_row1_row1 {}
}


