.profileeditpage_menubar_mobview {
    display: none;
}
.profilepage_header{
    width: 100%;
    position: sticky;
    top: 0;
}
.profilepage_row{
    /* display: flex;
    justify-content: center; */
    /* width: 100%; */
}

@media only screen and (max-width: 500px) {
    .profileeditpage_menubar_mobview {
        display: flex;
        position: absolute;
        margin-top: -240px;
        width: 100%;
    }
}

@media only screen and (max-width: 350px) {
    .profileeditpage_menubar_mobview {
        display: flex;
        position: absolute;
        margin-top: -220px;
        width: 100%;
    }
}