.menubar {
  background-color: white;
  padding: 0 5%;
  width: 90%;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  color: rgb(117, 110, 110);
  border-bottom: 3px solid silver;
  /* position: sticky;
  top: 0px; */
  /* z-index: 1; */
}

.menubar_ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.menubar_li {
  list-style-type: none;
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: rgb(141, 121, 121);
  border-bottom: 3px solid white;
}

.menubar_li:hover {
  color: #fb2e86;
  display: flex;
  border-bottom: 3px solid #fb2e86;
}

.menubar_popup {
  position: absolute;
  display: none;
  background-color: white;
  width: 99.845%;
  left: 0;
  border: 1px solid gray;
  margin-top: 35px;
  z-index: 1;
}

.menubar_title {
  width: 100%;
  padding: 8px 0;
  justify-content: center;
  display: flex;
  /* flex-direction: row; */
}

.menubar_li:hover~.menubar_popup {
  display: flex;
}

.menubar_title:hover~.menubar_popup {
  display: flex;
}

.menubar_title_goldrate {
  display: none;
  margin-top: 8.5px;
  z-index: 1;
  position: absolute;
  background-color: #f4579b;
  width: 13.8%;
  padding-top: 20px;
  padding-bottom: 30px;
  border: 1px solid silver;
  margin-left: -2.5%;
  row-gap: 20px;
  justify-content: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: .85rem;

}

.menubar_title_goldrate label {
  /* display: flex; */
  /* margin-left: 50px; */
  justify-content: center;
}

.menubar_title:hover~.menubar_title_goldrate {
  display: flex;
  flex-direction: column;
  z-index: 2px;
  position: absolute;
  margin-top: 35.7px;
  margin-left: 30px;
  color: white;
  justify-content: center;
}

.menubar_title_goldrate_row1 {
  display: flex;
  border-bottom: 2px solid rgb(207, 201, 168);
  width: 85%;
  margin-left: 15px;
  padding-bottom: 10px;
}

.menubar_title_goldrate_row1 span {
  padding-left: 10px;
  padding-right: 10px;
}

.menubar_popup:hover {
  display: flex;
}

.menubar_popup_left {
  width: 15%;
  text-align: left;
}

.menubar_popup_left ul {
  padding: 0;
  display: flex;
  justify-content: left;
  flex-direction: column;
  margin: 0;
}

.menubar_popup_left li {
  border-bottom: 1px solid gray;
  padding: 10px 10%;
  background-color: white;
  justify-content: left;
  color: black;
  align-items: center;
  font-weight: normal;
  display: flex;
}

.menubar_popup_left_title {
  display: flex;
  flex-direction: column;
}

.menubar_popup_left_title_bold {
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

.menubar_popup_left_img img {
  width: 30px;
  height: 30px;
}

.menubar_popup_right_one_img img {
  width: 30px;
  height: 30px;
}

.menubar_popup_left li:hover {
  font-weight: bold;
}

.menubar_popup_right {
  display: flex;
  width: 85%;
  justify-content: space-around;
}

.menubar_popup_right h3 {
  color: rgb(161, 160, 160);
  font-size: 0.9rem;
}

.menubar_popup_right ul {
  padding: 0;
  margin: 0;
}

.menubar_popup_right li {
  color: black;
  padding: 10px 0;
  font-weight: normal;
  display: flex;
  align-items: center;
}

.menubar_popup_right li:hover {
  font-weight: bold;
}

.menubar_popup_icon {
  margin-right: 10px;
}

@media only screen and (max-width:1500px){
  .menubar_title_goldrate {
    width: 25%;
    margin-left: -300px;
  }
   .menubar_title:hover~.menubar_title_goldrate {
    display: flex;
    flex-direction: column;
    z-index: 2px;
    position: absolute;
    margin-top: 35.7px;
    margin-left: -30px;
    color: white;
    justify-content: center;
  }
 
}

@media only screen and (max-width: 900px) {
  .menubar {
    width: 90%;
  }

  .menubar_popup {
    width: 99.6%;
  }

  .menubar_title_goldrate {
    width: 30%;
    margin-left: -150px;
  }
  .menubar_title:hover~.menubar_title_goldrate {
    display: flex;
    flex-direction: column;
    z-index: 2px;
    position: absolute;
    margin-top: 35.7px;
    margin-left: -55px;
    color: white;
    justify-content: center;
  }
  
}

@media only screen and (max-width: 500px) {

  /* .menubar{
    width: 60%;
  } */
  .menubar_ul {
    display: flex;
    flex-direction: column;
  }

  .menubar_title_goldrate {
    width: 98%;
    margin-left: -14.7px;
  }

  .menubar_popup_right {
    /* display: flex; */
    /* position: absolute; */
    /* z-index: 1; */
    margin-left: 40px;
  }

  .menubar_popup {
    width: 99.5%;
  }
}


@media only screen and (max-width: 350px) {
  .menubar_ul {
    display: flex;
    flex-direction: column;
  }

  .menubar_title_goldrate {
    width: 98%;
    margin-left: -14.7px;
  }

  .menubar_popup_right {
    /* display: flex; */
    /* position: absolute; */
    /* z-index: 1; */
    margin-left: 40px;
  }

  /* .menubar_popup_left_title_bold:hover~.menubar_popup_right{
    position: absolute;
    z-index: -1;
    display: flex;
  width: 85%;
  justify-content: space-around;
  }
  .menubar_popup_right_mobview{
    position: absolute;
    z-index: 1;
    display: flex;
  width: 85%;
  justify-content: space-around;
  } */

}


