/* .Mainbanner {
    display: flex;
    flex-direction: column;
    background: #F2F0FF;
}

.Mainbanner_main {
    display: flex;
    flex-direction: row;
}

.Mainbanner_main_center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: -89px;
    padding-top: 106px;
}

.Mainbanner_main_center_l1 {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 28px;
    color: #FB2E86;
}

.Mainbanner_main_center_l2,
.Mainbanner_main_center_l3 {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 2rem;
    line-height: 35px;
    letter-spacing: 2.015px;
    color: #000000;
    font-weight: 700;
    width: max-content;
    padding-bottom: 10px;
}

.Mainbanner_main_center_l4,
.Mainbanner_main_center_l5 {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 0.7rem;
    /* line-height: 20px; */
  
    /* letter-spacing: 0.7px; */
/* } */

/* .Mainbanner_main_center_button button {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    background-color: rgb(224, 42, 139);
    outline: none;
    border: none;
    /* padding: 5px; */
    /* margin-top: 20px;
    width: 163px;
    height: 50px;

}

.Mainbanner_main_right img {
    width: 606px;
    height: 589px;
} */

/* .Mainbanner_bottom img {
    width: min-content;
    height: 14.6px;
    padding-bottom: 57px;
}

.Mainbanner_bottom {
    display: flex;
    justify-content: center;
} */

 
/* @media only screen and (max-width: 500px) {
    .Mainbanner_main{
        flex-direction: column;
    }
    .Mainbanner_main_center{
        margin-left: 10px;
    }
    .Mainbanner_main_right,.Mainbanner_main_right_image1 img{
        max-width: 100%;
        height: auto;
    }
} */ 
/* .mainbanner_main{
    background-attachment: fixed;
} */


/**************/

/************************/

.slider{
    display: flex;
    border-radius: 10px;
    overflow: hidden;
}
.slider_inner{
    width:500%;
    /* height: 500px; */
    display:flex;
}
.slider_inner input{
    display: none;
}
/* .slide{
    width: 20%;
    transition: 2s;
}
.slide img{
    width: 100%;
    height: 700px;
} */

/*****navigation***********/

/* .navigation_manual{
  position: absolute;
  width: 800px;
  margin-top: 460px;
  display: flex;
  justify-content: right;
  padding-left: 70px;
}
.mainbtncolor{

    background: white;
}
.manual_btn{
   border: 2px solid white;
   padding: 5px;
   border-radius: 10px;
   cursor: pointer;
   transition: 1s; 
}
.manual_btn:not(:last-child){
    margin-right: 40px;
}.manual_btn:hover{
    background: white;
}
#radio1:checked ~ .first{
    margin-left: 0;
}
#radio2:checked ~ .first{
    margin-left: -20%;
}

#radio3:checked ~ .first{
    margin-left: -40%;
}

#radio4:checked ~ .first{
    margin-left: -60%;
}
#radio5:checked ~ .first{
    margin-left: -80%;
} */

/*********automatic navigation***********/

/* .navigation_auto{
    position: absolute;
    display: flex;
    width: 800px;
    justify-content: right;
    margin-top: 460px;
    padding-left: 70px;
}
.navigation_auto div{
    border: 2px solid white;
   padding: 5px;
   border-radius: 10px;
   transition: 1s;
}
.navigation_auto div:not(:last-child){
    margin-right: 40px;
}
.radio1:checked ~ .navigation_auto .auto_btn1{
    background-color: white;
}
.radio2:checked ~ .navigation_auto .auto_btn2{
    background-color: white;
}
.radio3:checked ~ .navigation_auto .auto_btn3{
    background-color: white;
}
.radio4:checked ~ .navigation_auto .auto_btn4{
    background-color: white;
}
.radio5:checked ~ .navigation_auto .auto_btn5{
    background-color: white;
}

 */



/* //slider css */
/* Slideshow */

/* .slideshow {
    margin: 0 auto;
    overflow: hidden;
    max-width: 500px;
  }
  
  .slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
  }
  
  .slide {
    display: inline-block;
  
    height: 400px;
    width: 100%;
    border-radius: 40px;
  }
   */
  /* Buttons */
  
  /* .slideshowDots {
    text-align: center;
  }
  
  .slideshowDot {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
  
    cursor: pointer;
    margin: 15px 7px 0px;
  
    background-color: #c4c4c4;
  }
  
  .slideshowDot.active {
    background-color: #6a0dad;
  } */

  @media only screen and (max-width: 500px) {
    .slider{
        display: block;
        background-color: #fb2e86;
        border-radius: 0px;
        /* width: 70%; */
    }
    .slide img{
        /* width: 1600px; */
        width: 90%;
        /* height: 700px; */
    }
    
}
@media only screen and (max-width: 350px) {
    .slider{
        display: block;
        background-color: #fb2e86;
        border-radius: 0px;
    }
}