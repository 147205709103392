.listview {
  /* background-color: green; */
  padding: 10px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  /* width: 65%; */
}

.singlelist {
  display: flex;
  flex-direction: row;
  width: 120%;
  background-color: #FFFFFF;
  padding: 15px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  margin-bottom: 2%;
  padding-right: 80px;

}

.singlelist_col1 {
  display: flex;
}

.singlelist_col2_row1 {
  display: flex;
  flex-direction: row;
}

.singlelist_col2_row1 h4 {
  color: #fb2e86;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.singlelist_col2_row1_dots {
  display: flex;
  flex-direction: row;
  padding-top: 21px;
  padding-left: 85px;
}

.singlelist_col2_row2 {
  display: flex;
}

.singlelist_col2_row2 span,
s {
  padding-right: 10px;
}

.singlelist_col2_row2_img {
  padding-left: 15px;
  display: flex;
}

.singlelist_col2_row2_label1 {
  color: #fb2e86;
  font-size: 0.8rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.singlelist_col2_row2_label2 {
  color: #ff2aaa;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.8rem;
}

.singlelist_col2_row3 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.singlelist_col2_row3 label {
  color: #7E81A2;
}

.singlelist_col1 img {
  width: 150px;
  height: 185px;
}

.singlelist_col2_row4 {
  display: flex;
  flex-direction: row;
}

.singlelist_col2_row4 img {
  /* padding-right: 25px; */
  justify-content: center;
  padding-left: 10px;
  display: flex;
}

.singlelist_col2_row4_circle {
  display: flex;
  width: 31px;
  height: 31px;
  background-color: #fdf9f9;
  border-radius: 50%;
  margin-right: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media only screen and (max-width:550px){
  .singlelist_col1 img {
    width: 120px;
    height: 120px;
  }
  .singlelist{
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    padding-right: 0px;
  }
  .singlelist_col1{
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 500px) {
  .listview {
    width: 100%;
    display: flex;
    justify-content: center;

  }

  .singlelist {
    width: 150%;
    display: flex;
    flex-direction: column;
    margin-left: -70px;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 20px;
    margin-left: 45px;
  }

  .singlelist_col1 {
    display: flex;
    justify-content: center;
  }

  .singlelist_col2 {
    padding-left: 10px;
  }
}

@media only screen and (max-width:415px){
  .singlelist{
    margin-left: 42px;
  }
}

@media only screen and (max-width:395px){
  .singlelist{
    margin-left: 40px;
  }
}

@media only screen and (max-width:380px){
  .singlelist{
    margin-left: 38px;
  }
  /* .listview{
    padding: 0px;
  } */
}

@media only screen and (max-width: 350px) {
  .listview {
    /* width: 140%; */
  }

  .singlelist {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 35px;
    /* padding-left: 0;   */
    padding-right: 0;
    margin-bottom: 20px;
  }

  .singlelist_col1 {
    display: flex;
    justify-content: center;
  }

  .singlelist_col2 {
    padding-left: 10px;
  }
}

@media only screen and (max-width:280px){
  .singlelist{
    margin-left: 0px;
  }
}