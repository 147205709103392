.singlecheckout {
  width: 100%;
  justify-content: center;
  display: flex;
  padding-bottom: 20px;
  border-bottom: 1px solid silver;

}
.checkout_right_cont_navigation{
  display: flex;
justify-content: right;
margin-top: -20px;
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
font-size: .85rem;
font-weight: 500;
text-decoration: underline;
color: #15245e;

}
.checkout_right_cont_empty{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: .85rem;
  /* font-weight: 500; */
}
.Checkout_cont {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 20px;
  /* border-bottom: 1 px solid #8b8bd8; */
  /* background-color: yellow; */
}

.checkout_right_cont {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* justify-content: space-between; */
  /* border-bottom: 1px solid #6b6bbe; */
}

.checkout_cont_product_label {
  display: flex;
  flex-direction: column;
}

.checkout_cont_product img {
  height: 50px;
  width: 50px;
}

.checkout {
  display: flex;
  flex-direction: row;
  padding: 10px;
  justify-content: center;
  padding-top: 100px;
}


.checkout_left_calculate {
  display: flex;
  flex-direction: column;
}

.checkout_left_calculate input {
  display: flex;
  flex-direction: column;
}

.checkout_right_cont_button {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  justify-content: space-evenly;
  margin-bottom: 100px;
}
.checkout_right_cont_button2 {
  display: flex;
  flex-direction: row;
  padding-top: 15px;
  justify-content: space-evenly;
  /* margin-bottom: 100px; */
}


.checkout_right_cont_button_btn2 {
  margin-left: 77%;
  padding: 3px;
}

.checkout_right_cont_button_btn2 button {
  /* display: flex; */

  background: #fb2e86;
  border-radius: 2px;
  border: none;
  outline: none;
  color: white;
  padding: 15px;
  width: max-content;
}
.checkout_right_cont_button_btn3 button {
  /* display: flex; */

  background: #fb2e86;
  border-radius: 2px;
  border: none;
  outline: none;
  color: white;
  padding: 10px;
  width: max-content;
}


.checkout_right_cont_button_btn1,
.checkout_right_cont_button_btn2 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  display: flow-root;
}

.checkout_right_cont_button_btn1 button {
  background: #fb2e86;
  border-radius: 2px;
  border: none;
  outline: none;
  color: white;
  padding: 15px;
  width: max-content;
}

.checkout_right_header_h3 label {
  padding-right: 19px;
}

.checkout_cont_product_label {
  padding-left: 10px;
  gap: 5px;
}

.checkout_cont_product_label_l1 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  color: #000000;
}

.checkout_cont_product_label_l2,
.checkout_cont_product_label_l3 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  color: #a1a8c1;
}




.checkout_left {
  margin-left: 80px;
  width: 23%;
}

.checkout_right {
  width: 60%;
  margin-left: 30px;
  justify-content: center;
}

.checkout_left_total_body {
  background: #F8F8FD;
  border-radius: 3px;
  padding: 20px;
  display: grid;
  gap: 20px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.checkout_left_total_header {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1rem;
  /* font-weight: 500; */
  color: #fb2e86;
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
}

.checkout_left_total_body_row1 {
  /* display: flex; */
  display: none;
  flex-direction: row;
  font-family: "segoe ui";
  font-size: 20px;
  line-height: 23px;
  width: 100%;
  color: #1d3178;
  border-bottom: 1px solid solid #e8e6f1;
}

.checkout_left_total_body_row1_l1 {
  width: 50%;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1rem;
  font-weight: 500;
}

.checkout_left_total_body_row1_l2 {
  width: 50%;
  /* text-align: end; */
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1rem;
  font-weight: 500;
}

.checkout_left_total_body_row2 {
  /* display: flex; */
  display: none;
  flex-direction: row;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  color: #1d3178;
  border-bottom: 1px solid solid #e8e6f1;
}

.checkout_left_total_body_row3 {
  display: flex;
  flex-direction: row;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  color: #1d3178;
  border-bottom: 1px solid solid #e8e6f1;
}

.checkout_left_total_body_row3_l2 input {
  border: none;
  outline: none;
}

.checkout_left_total_body_row2_l1 {
  width: 50%;
}

.checkout_left_total_body_row2_l2 {
  width: 50%;
  /* text-align: end; */
}


.checkout_left_total_body_row3_l1 {
  width: 50%;
}

.checkout_left_total_body_button button {
  background: #fd4387f0;
  border-radius: 3px;
  border: none;
  outline: none;
  color: white;
  padding: 10px;
  width: 100%;
}

.checkout_left_total_body_row1_l2 input {
  border: none;
  outline: none;
  width: 100%;
}

.checkout_left_total_body_row2_l2 input {
  border: none;
  outline: none;
  width: 100%;
}

.checkout_left_total_body_button {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
}

.checkout_left_calculate_header {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1rem;
  /* font-weight: 500; */
  color: #fb2e86;
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
}

.checkout_left_calculate_body {
  display: flex;
  flex-direction: column;
  background: #F8F8FD;
  border-radius: 3px;
  padding: 20px;
  display: grid;
  gap: 30px;
  width: 100%;
}

.checkout_left_calculate input {
  border: none;
  outline: none;
  width: 100%;
}

.checkout_left_calculate_body_l1 {
  font-family: "segoe ui";
  font-size: 14px;
  line-height: 16px;
  color: #c5cbe3;
  border-bottom: 1px solid #c5cbe3;
}

.checkout_left_calculate_body_l2 {
  font-family: "segoe ui";
  font-size: 14px;
  line-height: 16px;
  color: #c5cbe3;
  border-bottom: 1px solid #c5cbe3;
}

.checkout_left_calculate_body_l3 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  color: #c5cbe3;
  border-bottom: 1px solid #c5cbe3;
}

.checkout_left_calculate button {
  background: #fd4387f0;
  border-radius: 2px;
  border: none;
  outline: none;
  color: white;
  padding: 20px;
}

.checkout_right_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #fb2e86;
  /* background-color: gray; */
  width: 100%;
  padding-bottom: 20px;
}

.checkout_right_header_h1 {

  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  width: 60%;

}

.checkout_right_header_h2 {

  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  padding-left: 42px;
  width: 40%;
}

.checkout_right_header_h3 {

  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  width: 45%;

}

.checkout_right_header_h4 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  width: 15%;
}

.checkout_cont_quantity input {
  width: 10px;
}

.checkout_cont_quantity {
  display: flex;
  flex-direction: row;
  height: 20px;
}

.checkout_cont_quantity input {
  background-color: #f4f4fc;
  border: none;
  outline: none;
  color: #7d8fb3;
}

.checkout_cont_quantity button {
  border: none;
  outline: none;
  color: #7d8fb3;
}

.checkout_cont_product {
  display: flex;
  flex-direction: row;
  justify-content: left;
  width: 50%;
  margin-right: 100px;
}

.checkout_cont_price {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  color: #15245e;
  justify-content: center;
  width: 22%;
  margin-right: 100px;
}

.checkout_cont_quantity {
  width: 27%;
  margin-right: 100px;

}

.checkout_cont_total {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  color: #15245e;
  width: 15%;
}

.checkout_left_total_body_row1_l1_s1 {
  padding-left: 80px;
}

.checkout_left_total_body_row1_l1_s2 {
  padding-left: 19px;
}

.checkout_left_total_body_row1_l1_s3 {
  padding-left: 106px;
}

@media only screen and (max-width:1500px){
  .checkout{
    /* width: 100%; */
    display:  flex;
    flex-direction: column;
    justify-content: center;
    /* background-color: yellow; */
  }
  .checkout_right{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background-color: gainsboro; */
  }
  .checkout_left{
    width: 80%;
    display: flex;
    justify-content: center;
    /* background-color: yellow; */
  }
  .checkout_left_total{
    width: 45%;
  }
  
}

@media only screen and (max-width:1030px){
  .checkout_right_header_h1{
    width: 35%;
  }
  .checkout_right_header_h2{
    width: 25%;
  }
  .checkout_right_header_h3{
    width: 25%;
  }
  .checkout_right_header_h4{
    width: 15%;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width:920px){
  .checkout_right_header_h2{
    /* padding-left: 0px; */
  }
}

@media only screen and (max-width:830px){
  .checkout_cont_product{
    width:40%;
    margin-right: 0px;
  }
  .checkout_cont_price{
    width: 25%;
    margin-right: 0px;
  }
  .checkout_cont_quantity{
    width: 20%;
    margin-right: 0px;
  }
  .checkout_right_header_h1{
    width: 35%;
    display: flex;
  }
  .checkout_right_header_h2{
    width: 20%;
    display: flex;
    justify-content: center;
  }
  .checkout_right_header_h3{
    width: 30%;
    display: flex;
    justify-content: center;
    padding-left: 20px;
  }
  .checkout_right_header_h4{
    width: 15%;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width:770px){
  .checkout_right_header_h2{
    padding-left: 0px;
  }
  .checkout_cont_product{
    margin-right: 0px;
    width: 30%;
  }
  .checkout_cont_price{
    margin-right: 0px;
    width: 25%;
    display: flex;
    justify-content: center;
  }
  .checkout_cont_quantity{
    margin-right: 0px;
    width: 25%;
    display: flex;
    justify-content: center;
  }
  .checkout_cont_total{
    width: 20%;
    display: flex;
    justify-content: right;
  }
  .checkout_cont_total label{
    padding-left: 10px;
  }
}

/* @media only screen and (max-width:650px){
  .Checkout_cont{
    width: 150%;
  }
.checkout_cont_product{
  margin-left: 50px;
  display: flex;
  justify-content: center;
  width: 40%;
}
.checkout_cont_price{
  width: 20%;
  margin-right: 50px;
}
.checkout_cont_quantity{
  margin-right: 50px;
}
.checkout_right_header_h2{
  margin-left: -20px;
}
.checkout_right_header_h4{
  margin-left: -20px;
}
} */

@media only screen and (max-width:550px){
  .Checkout_cont{
    margin-left: -30px;
    padding-top: 10px;
  }
  .checkout_cont_price{
    margin-right: 0;
    width: 25%;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .checkout_cont_price label{
    width: 100%;
  }
  .checkout_cont_product{
    width: 30%;
    display: flex;
    flex-direction: column;
  }
  .checkout_cont_product_img{
    display: flex;
    justify-content: center;
  }
  .checkout_cont_quantity{
    width: 25%;
    display: flex;
    justify-content: center;
  }
  .checkout_cont_total{
    width: 20%;
    display: flex;
    justify-content: right;
  }
  .checkout_right_header_h4 {
    width: 20%;
  }
  .checkout_right_header_h3 {
    width: 40%;
  }
  .checkout_left {
    margin-left: 0px;
    width: 100%;
  }
  .checkout_left_total_body_row1_l1_s3 {
    padding-left: 50px;
  }
}

@media only screen and (max-width: 500px) {
  .checkout {
    display: flex;
    flex-direction: column;
  }

  .checkout_right {
    display: contents;
  }

  .checkout_right_header {
    display: flex;
  }

  .checkout_right_cont {}

  .singlecheckout {}

  .Checkout_cont {
    display: flex;
    flex-direction: row;
    /* background-color: green; */
    gap: 0px;
    margin-left: 0px;
  }

  .singlecheckout {
    width: 100%;
    gap: 0px;
    /* background-color: yellow; */
  }

  .checkout_right_header_h2 {
    /* padding-left: 88px; */

  }
.checkout_right_cont_button{
  margin-bottom: 50px;
  margin-left: -300%;
}
  .checkout_right_cont_button_btn2 {}

  .checkout_right_header_h1 {}

  .checkout_cont_product {
    display: flex;
    width: 35%;
    display: flex;
    /* background-color: yellow; */
    margin-right: 0px;
margin-left: 0px;
  }

  .checkout_cont_price {
    display: flex;
    width: 22%;
    margin-right: 0px;
    /* background-color: gold; */
    /* margin-left: -35px; */
  }
  .checkout_cont_total_inner label{
    padding-left: 15px;
  }

  .checkout_cont_quantity {
    display: flex;
    width: 25%;
    /* background-color: yellow; */
    justify-content: center;
    gap: 5px;
    margin-right: 0px;
    /* margin-left: -15px; */
  }
  .checkout_left_total{
    margin-left: 90px;
  }

  .checkout_cont_total {
    display: flex;
    /* flex-direction: column; */
    width:18%;
    justify-content: left;
    margin-right: 0px;
    /* margin-top: -30px; */
    margin-left: -20px;
    /* background-color: gold; */
    /* padding-right: 10px; */
  }
  .checkout_cont_total_inner{

  }


  .checkout_cont_product_label {
    padding-left: 0px;
  }

  .checkout_cont_product img {
    width: 50px;
  }

  .checkout_cont_quantity button {
    height: 16px;
  }
  .checkout_cont_quantity input {
    height: 16px;
  }


  .checkout_left {
    display: flex;
    width: 80%;
  }
  .checkout_left_total{
    width: 95%;
  }

  .checkout_left_total_body {
    width: 90%;
  }

  .checkout_right_header_h1 {
    width: 35%;
    display: flex;
    justify-content: center;
    /* background-color: yellow; */
  }

  .checkout_right_header_h2 {
    width: 25%;
    /* background-color: gold; */
    display: flex;
    justify-content: center;
    padding-left: 0px;
  }

  .checkout_right_header_h3 {
    width: 25%;
    display: flex;
    justify-content: center;
    padding-left: 10px;
    /* background-color: yellow; */
  }
  .checkout_right_header_h4{
    display: flex;
    justify-content: center;
    /* margin-left: -20px; */
    /* width: 25%; */
  }
}

@media only screen and (max-width:420px){
  .checkout_cont_product{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
  }
  .checkout_right_header{
    padding-bottom: 5px;
  }
  .checkout_left_total{
    margin-left: -60px;
  }
  .Checkout_cont{
    margin-left: 0px;
  }
  .checkout_left{
    margin-left: 20%;
  }
}

@media only screen and (max-width:400px){
  .checkout_left{
  }
  .checkout_left_total_body_row3{
    width: 100%;
  }
  .checkout_left_total_body_row1_l1_s3{
    padding-left: 15%;
  }
  .checkout_left_total_body{
    margin-left: -50px;
  }
  .checkout_cont_product{
    display: flex;
    flex-direction: column;
  }
  .checkout_right_header{
    padding-bottom: 5px;
  }
  .checkout_left_total{
    margin-left: 20px;
  }
  .checkout_left_total_header{
    margin-left: -80px;
  }
  .checkout_right_cont_button_btn3 button {
    margin-top: 20px;
  }
  .checkout_right_cont_button_btn3 button {
    padding: 5px;
  }
  .Checkout_cont{
    margin-left: 0px;
  }
  .checkout_cont_total{
    display: flex;
    justify-content:left;
  }
}

@media only screen and (max-width:365px){
  .checkout_right_header_h3{
    display: flex;
    justify-content: right;
    /* width:45%; */
  }
  .checkout_right_header_h4{
    width: 15%;
  }
}

@media only screen and (max-width: 350px) {
  .checkout {
    display: flex;
    flex-direction: column;
  }

  .checkout_right {
    display: contents;
  }

  .checkout_right_header {
    display: flex;
  }

  .checkout_right_cont {}

  .singlecheckout {}

  .Checkout_cont {
    display: flex;
    flex-direction: row;
    gap: 11px;
  }

  .singlecheckout {
    width: 100%;
  }

  .checkout_cont_price {
    display: contents;

  }

  .checkout_right_header_h2 {
    padding-left: 88px;

  }

  .checkout_cont_product {
    display: contents;

  }

  .checkout_cont_quantity {
    display: contents;

  }

  .checkout_cont_product_label {
    padding-left: 0px;
  }

  .checkout_cont_product img {
    width: fit-content;
  }

  .checkout_cont_quantity button {
    height: 16px;
  }

  .checkout_cont_quantity input {
    height: 16px;
  }

  .checkout_cont_total {
    display: contents;

    padding-right: 10px;
  }

  .checkout_left {
    display: contents;
  }

  .checkout_left_total_body_button {
    padding-right: 40px;
  }
}

@media only screen and (max-width:290px){
  .checkout_right_header_h1{
    font-size: .8rem;
    width: 30%;
  }
  .checkout_right_header_h2{
    padding-left: 0px;
    font-size: .8rem;
    width: 20%;
    display: flex;
    justify-content: right;
  }
  .checkout_right_header_h3{
    font-size: .8rem;
    width: 32%;
    display: flex;
    justify-content: right;
  }
  .checkout_right_header_h4{
    font-size: .8rem;
    width: 18%;
    display: flex;
    justify-content: center;
  }
  .Checkout_cont{
    gap: 0;
  }
  .checkout_cont_product{
    display: flex;
    flex-direction: column;
    width: 50%;
    /* font-size: .6rem; */
  }
  .checkout_cont_price{
    width: 15%;
  }
  .checkout_cont_quantity{
    width: 20%;
  }
  .checkout_cont_total{
    width: 15%;
  }
  .checkout_cont_product_label_l1{
    font-size: .6rem;
  }
  .checkout_cont_product_label_l2, .checkout_cont_product_label_l3{
    font-size: .5rem;
  }
  .checkout_cont_price label{
    font-size: .6rem;
  }
  .checkout_cont_price label{
    font-size: .6rem;
    width: 50%;
  }
  .checkout_cont_total_inner label{
    font-size: .62rem;
  }
  .checkout_right_cont_button_btn3 button{
    padding: 5px;
    font-size: .7rem;
  }
  .checkout_left_total_body{
    margin-left:0px ;
    width: 80%;
  }
  .checkout_right_cont_button_btn2 button {
    padding: 10px;
  }
  .Checkout_cont{
    margin-left: 0px;
  }
}

/* @media only screen and (max-width: 900px) {
  .checkout{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .checkout_left_total_body{
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding: 50px;
  }
  .checkout_left_total_body_row3_l1{
    display: flex;
    flex-direction: row;
  }
  .checkout_left_total_body_row3{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .checkout_right_header_h3{
    display: flex;
  }
  .checkout_right_header_h4{
    display: flex;
    justify-content: center;
    width: 30%;
  }
  .checkout_right_header{
    width: 120%;
    display: flex;
    
  }
} */