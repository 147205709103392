.InBetweenBanner2 {
    /* background-attachment: fixed; */
    background-size: cover;
    /* width: 100%; */
    height: 60vh;
    display: flex;
    flex-direction: column;
    padding-top: 19%;
    padding-bottom: 19%;
    padding-left: 55%;
    text-align: center;
}

.InBetweenBanner2_label1 {
    font-size: 35px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 2rem;
    font-weight: 650;
    /* letter-spacing: 0.015em; */
    color: #FFFFFF;

}

.InBetweenBanner2_label2 {

    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 2rem;
    font-weight: 650;

    color: #FFFFFF;
    /* font-weight: bold; */

}

.InBetweenBanner2_button button {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
    color: #FFFFFF;
    background-color: rgb(248 147 16);
    outline: none;
    border: none;
    padding: 10px;
    margin-top: 20px;
    Width: 173px;
    height: 49px;

}

/****************************************/
@media only screen and (max-width: 900px) {
    .InBetweenBanner2 {
        display: flex;
        width: 45%;
    }

}

@media only screen and (max-width: 500px) {

    /* .InBetweenBanner1_container {
        display: flex;
        flex-direction: column;
        margin: 0;
    }

    .InBetweenBanner1_leftimg_col1 {
        padding: 0;
    }

    .InBetweenBanner1_leftimg_col1 img {
        max-width: 300px;
    }

    .InBetweenBanner1_righttext_col2 {
        width: 100%;
        padding: 0px;
    } */
    .InBetweenBanner2 {
        display: block;
        /* width: 50%; */
    }
    .InBetweenBanner2_button button{
        margin-left: -20px;
    }
}

@media only screen and (max-width: 350px) {
    .InBetweenBanner2 {
        display: flex;
        width: 45%;
    }
    .InBetweenBanner2_button{
        margin-left: -50px;
    }
}

