.section {
  width: 100%;
  /* margin: auto; */
  margin-top: 1%;
  /* margin-left: 5%; */
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto;
}

.Gridview_mobileview {
  /* display: none; */
  padding-bottom: 20px;
  justify-content: center;
}

.Singlegridview {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;
  /* margin-left: 30%; */
  /* margin-right: 10px; */
  /* background-color: yellow; */
}

.gv-outer-cover-r1,
.gv-outer-cover-r2,
.gv-outer-cover-r3 {
  display: flex;
  flex-direction: row;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  /* box-sizing: border-box;
  display: table; */
}

.gv-r1-blue-dlor {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.9rem;

  /* font-weight: 500; */
  /* padding-left: 64px; */
  /* padding-right: 11px; */
}

.gv-r1-pink-dollr {
  color: brown;
  font-size: 0.9rem;
}

.gv-r1-mooncolor {
  padding-left: 70px;
}
.gv-r1-mooncolor img {
  width: 50px;
}
.gv-r1-cover {
  background-color: #fcf8f8;
  padding: 10px;
  margin-bottom:30px;
}


.gv-r1-chair-1 {
  /* background-color: yellow; */
  /* background-color: rgb(255, 255, 255); */
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.gv-chr1-dollor-lbl {
  display: flex;
  justify-content: center;
}

.gv-r1-chr-1-pic {
  /* background-color: rgb(209, 100, 100); */
  padding-left: 15px;
  padding-right: 15px;
  /* margin-right: 22px; */
  margin-bottom: 22px;
  margin-top: 39px;
}

.gv-r1-chr-1-pic img {
  width: 150px;
  height: 150px;
  /* margin-left: 10px; */
}

.gv-r1-vel-ethit-eusmod {
  text-align: center;
  /* justify-content: center; */
  /* background-color: yellow; */
  /* padding-left: 44px; */
  padding-bottom: 5px;
  font-weight: 550;
  padding-left: -10px;
  /* margin-left: -10px; */
  color: #fb2e86;
  font-size: 1rem;
}
.gv-r1-vel-ethit-eusmod1{
  text-align: center;
  font-size: .85rem;
  color: #fb2e86;

}
.Gridview_mobileview_inner {
  display: flex;
  background-color: aqua;
  /* justify-content: space-between; */
  /* flex-direction: row; */
  width: 100%;
}

.gv-span {
  /* font-weight: 500; */
  font-size: .8rem;
  /* padding-left: 45px; */
}

/*****************************/

@media only screen and (max-width: 1500px) {
  .section {
    /* width: 80%; */
    margin-top: 5%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
  }

}


@media only screen and (max-width: 800px) {
  .section {
    /* width: 80%; */
    margin-top: 5%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
  }

}

@media only screen and (max-width: 700px){
  .section {
    /* width: 80%; */
    margin-top: 5%;
    display: flex;
    flex-direction: column;
  
    /* display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto; */
  }
  
}

@media only screen and (max-width:580px){
  section {
    /* width: 80%; */
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    /* grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto; */
  }
  
}

@media only screen and (max-width: 500px) {
  .gridview {
    display: flex;
    justify-content: center;
    /* width: 90%;
    margin-left: 35px; */
  }

  .section {
    /* width: 80%;
    margin-top: 5%; */
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    /* margin-left: -110px; */
    /* column-gap: 30px; */

  }

  .gv-outer-cover-r1 {
    display: table;
    box-sizing: border-box;
  }

  .gv-outer-cover-r2,
  .gv-outer-cover-r3 {
    display: none;
  }

  /* .section {
    display: contents;
  } */

  /* .Gridview_mobileview {
    display: block;
  } */

  /* .gv-outer-cover-r2,
.gv-outer-cover-r3{
  display: none;
} */
}

@media only screen and (max-width: 350px) {
  .gv-outer-cover-r1 {
    /* display: table;
    box-sizing: border-box; */
  }

  .gv-outer-cover-r2,
  .gv-outer-cover-r3 {
    /* display: none; */
  }

  .gv-r1-cover {
    background-color: #fcf8f8;
    padding: 10px;
    margin-bottom:30px;
  }

  .section {
    display: inline-table;
  }

  /* .Gridview_mobileview {
    display: block;
  } */

  /* .gv-outer-cover-r2,
.gv-outer-cover-r3{
  display: none;
} */
}
