.Login_borderbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 400px; */
    width: 30%;
    /* margin-left: 100px; */
    /* margin-top: 60px; */
    padding: 20px 50px;
    background-color: white;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);

}

.Login_l1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* padding-bottom: 20px; */
    /* font-size: 1.8rem; */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
}

.Login_l1 h2 {
    font-size: 1.8rem;
    color: #FB2E86;
}

.Login_l2 {
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #9096B2;
    font-size: .9rem;
}

.Login_logindetails {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;

}

.Login_logindetails input {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #C2C5E1;
    color: #9096B2;
    border-radius: 2px;
    outline: none;
}

.Login_forgot {
    padding-top: 8px;
    padding-bottom: 12px;
}

.Login_forgot label {
    color: #9096B2;
    background-color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: .9rem;

}

.Login_button2 {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 25px;
    border: none;

}

.Login_button2 button {
    width: 100%;
    color: white;
    background-color: #FB2E86;
    border: none;
    outline: none;
    padding: 10px 0;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 3px;
}

.Login_lastline {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 50px;
    /* font-weight: 500; */
}

.Login_lastline label {
    font-size: .9rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #9096B2;
}

.Login_lastline_lab2 {
    padding-left: 5px;
}

/*************************************************/

@media only screen and (max-width:1500px){
    .Login_borderbox{
        width: 45%;
        margin-left: 0px;
    }
}

@media only screen and (max-width:1280px){
    .Login_borderbox{
        width: 35%;
        margin-left: 0px;
    }
}

@media only screen and (max-width:540px){
    .Login_borderbox{
        width: 45%;
        margin-left: 0px;
    }
    .Header_r2_col1_logo{
        padding-left: 10px;
    }
    .Header_r2_col1_logo img{
        width: 80px;
    }
    .Header_r1_right {
        gap: 10pxs;
    }
}

@media only screen and (max-width: 500px) {
    .Login_borderbox {
        margin: 0;
        /* margin-left: 10px; */
        display: flex;
        width: 71%;
    }
}

@media only screen and (max-width: 350px) {
    .Login_borderbox {
        display: flex;
        /* padding-left: 30px; */
        margin-left: 1px;
        width: 71%;
    }

    .loginpage_logsign {
        display: flex;
        flex-direction: column;
    }

    .loginpage_logsign_signup {
        display: contents;
        /* margin-left: -20px; */

    }
}

@media only screen and (max-width:290px){
    .Login_borderbox {
    width: 62%;
}
.Login_lastline{
    margin-top: -15px;
    display: flex;
    flex-direction: column;
}
}