.filterhorizontal {
  /* background-color:#F5F5F5; */
  display: flex;
  justify-content: space-between;
  /* padding-top: 40px;
  padding-bottom: 80px; */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #fb2e86;
}

.filterhorizontal_col2_row3_grid_outer {
  display: flex;
}

.filterhorizontal_col1 {
  /* background-color: rosybrown; */
  width: 50%;
  /* justify-items: center; */
  display: flex;
  flex-direction: column;
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
  margin-left: 9%;
}

.filterhorizontal_col1_row {
  /* align-items: center; */
  display: flex;
  flex-direction: column;
  padding-left: 15%;
}

.filterhorizontal_col1_row_label1 {
  font-size: 1rem;
  font-weight: 600;
  color: #fb2e86;
  /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
  /* justify-content: center; */
}

.filterhorizontal_col1_row_label2 {
  font-size: 0.7rem;
  color: #fb2e86;
}

.filterhorizontal_col2 {
  /* background-color: #08D15F; */
  display: flex;
  width: 45%;
  padding-top: 15px;
  /* padding-bottom: 15px; */
  margin-right: 5.5%;
}

.filterhorizontal_col2_row1 {
  /* display: flex; */
  /* padding-right: 15px; */
  display: none;
  /* background-color:#8A8FB9; */
}

.filterhorizontal_col2_row1 label {
  padding-right: 10px;
  color: #fb2e86;
  font-size: 0.8rem;
}

.filterhorizontal_col2_row1 input {
  /* border-color: #E7E6EF; */
  border: 1px solid #e7e6ef;
  outline: none;
  width: 50px;
}

.filterhorizontal_col2_row2 {
  /* display: flex; */
  align-items: center;
  /* width: 30%; */
  /* background-color: yellow; */
  /* padding-right: 15px; */
  display: none;
  /* background-color: #FB2E86; */
}

.filterhorizontal_col2_row2 label {
  padding-right: 10px;
  color: #fb2e86;
  font-size: 0.8rem;
}

.filterhorizontal_col2_row2 select {
  /* width: 120%; */
  /* display: flex; */
  /* width: 120%; */
  padding: 5px 0px;
  border-color: #e7e6ef;
  outline: none;
  width: 80px;
  font-size: 0.7rem;
  color: rgb(141, 136, 136);
}

.filterhorizontal_col2_row2 select option {
  font-size: 0.7rem;
  color: solid rgb(162, 158, 158);
}

.filterhorizontal_col2_row3 {
  display: flex;
  padding-left: 210px;
  align-items: center;
}

/* .filterhorizontal_col2_row3 label,
img {
  padding-right: 10px;
  color: #fb2e86;
  font-size: 0.8rem;
  padding-top: 5px;
} */

.filterhorizontal_col2_row3_grid,
.filterhorizontal_col2_row3_list {
  padding-top: 5px;
}

.filterhorizontal_col2_row3 input {
  /* margin-left: 10px; */
  border: 1px solid #e7e6ef;
  outline: none;
}

/***********************************************************************/
/*********************** end filterhorizontal page**********************/
/***********************************************************************/
@media only screen and (max-width:800px){
  .filterhorizontal{
    display: contents;
  }
}

@media only screen and (max-width: 500px) {
  .filterhorizontal {
    display: contents;
  }

  .filterhorizontal_col2 {
    display: inline-grid;
    gap: 10px;
  }

  .filterhorizontal_col1 {
    display: contents;
  }
}

@media only screen and (max-width: 350px) {
  .filterhorizontal {
    display: contents;
  }

  .filterhorizontal_col2 {
    display: inline-grid;
    gap: 10px;
  }

  .filterhorizontal_col1 {
    display: contents;
  }
}

@media only screen and (max-width:290px){
  .filterhorizontal_col2_row3{
    padding-left: 150px;
  }
}