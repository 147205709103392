.cartpage_menubar_mobview {
    display: none;
}
.cart_componentheader{
    position: sticky;
    top: 0;
    background-color: #c90053cf;
}

@media only screen and (max-width: 500px) {
    .cartpage_menubar_mobview {
        display: flex;
        position: absolute;
        margin-top: -220px;
        width: 100%;
    }
}

@media only screen and (max-width: 350px) {
    .cartpage_menubar_mobview {
        display: flex;
        position: absolute;
        margin-top: -240px;
        width: 100%;
    }
}

@media only screen and (max-width: 900px) {
    .componentcheckout {
        margin: 0px;
        width: 97%;
        /* background-color: yellow; */
    }
}