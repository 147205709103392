.Offerlist_images-cover {
  display: flex;
  flex-direction: row;
  /* background-color: aqua; */
  padding-left: 44px;
  justify-content: center;
}

.Offerlist_outercover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding-top: 33px; */
  /* padding-left: 33px; */
}

.offerlist-WhatShopexOffers {
  /* padding: 80px 0; */
  padding-bottom: 3%;
  margin-left: 7%; 
}

.offerlist-WhatShopexOffers {
  /* background-color: rgb(25, 0, 255); */
  color: #fb2e86;
  font-size: 22px;
}

.Offerlist-l1 {
  background-color: white;
  text-align: center;
  padding-bottom: 44px;
  color: #fb2e86;
  font-size: 2rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 650;
}

.Offerlist-hghhfgfgfhgf {
  /* background-color: rgb(217, 221, 212); */
  background-color: #f5f5f5;
  padding-left: 12px;
  padding-right: 12px;
  /* padding-bottom: 22px; */
  padding-top: 22px;
}

.Offerlist-ohyfffkgfkcgt-img-truck {
  padding-top: 22px;
  background-color: white;
}
.Offerlist-ohyfffkgfkcgt-img-truck img{
  width: 80px;
  height: 80px;
}
.Offerlist-img-cover {
  display: flex;
  flex-direction: row;
  /* background-color: blueviolet; */
  justify-content: center;
}

.Offerlist-img-truck {
  display: flex;
  flex-direction: column;
}

.Offerlist-oc_img-lbl {
  display: flex;
  flex-direction: column;
  background-color: white;
  /* padding: 0px 30px; */
}

.Offerlist-orderlisttttt {
  background-color: #f5f5f5;
  /* background-color:  rgb(217, 221, 212); */
  text-align: center;
  align-items: center;
  padding-bottom: 22px;
  /* padding-left: 32px; */
  /* padding-right: 22px; */
  /* padding: 20px; */
}

.Offerlist-img-hndgg {
  /* font-size: 1.2rem; */
  font-weight: 650;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #e7348f;
  padding-top: 15px;
  padding-bottom: 10px;
}
.Offerlist-under-lbl-img111_blank{
  padding-bottom: 10px;
}

.Offerlist-under-lbl-img {
  /* color: rgb(114, 114, 114); */
  /* color: lightgrey; */
  color: #fb2e8680;
  display: flex;
  flex-direction: column;
  padding-bottom: 22px;
  padding-left: 5px;
  padding-right: 5px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.6rem;
  font-weight: 500;
  /* padding-top: 22; */
}

@media only screen and (max-width:1200px){
  .Offerlist-img-cover{
    width: 95%;
    margin-left: 40px;
  }
  .Offerlist_outercover{
    display: flex;
    justify-content: center;
    /* background-color: yellow; */
  }
  .Offerlist-ohyfffkgfkcgt-img-truck img{
    width: 80px;
    height: 60px;
  }
  .Offerlist-img-hndgg{
    font-size: 1rem;
  }
  .Offerlist-under-lbl-img{
    font-size: .5rem;
  }
  .Offerlist-l1 {
    margin-top: 20px;
  }
}

@media only screen and (max-width:900px){
  .Offerlist-img-cover{
    width: 94.5%;
  }
  .Offerlist-ohyfffkgfkcgt-img-truck img{
    width: 60px;
    height: 40px;
  }
  .Offerlist-img-hndgg{
    font-size: .8rem;
  }
  .Offerlist-under-lbl-img{
    font-size: .4rem;
  }
}

@media only screen and (max-width:750px){
  .Offerlist-img-cover{
    width: 94%;
  }
  .Offerlist-ohyfffkgfkcgt-img-truck img{
    width: 50px;
    height: 40px;
  }
  .Offerlist-img-hndgg{
    font-size: .7rem;
  }
  .Offerlist-under-lbl-img{
    font-size: .3rem;
  }
  .Offerlist-hghhfgfgfhgf {
    padding-left: 6px;
    padding-right: 6px;
  }
  
}
@media only screen and (max-width:650px){
  .Offerlist-img-cover{
    width: 92%;
    margin-left: 52px;
  }
  
}
@media only screen and (max-width:600px){
  .Offerlist-img-cover{
    width: 91%;
    margin-left: 54px;
  }
}
@media only screen and (max-width:575px){
  .Offerlist-img-cover{
    width: 90%;
    margin-left: 56px;
  }
  .Offerlist-img-hndgg{
    font-size: .6rem;
  }
  
  /* .Offerlist_outercover{
    width: 99%;
  } */
}
@media only screen and (max-width:550px){
  .offerlist-WhatShopexOffers{
    width: 90%;
  }
}
@media only screen and (max-width: 500px) {
  .Offerlist-img-cover{
    /* width: 50%; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-left: 20px;
    justify-content: center;
  }
  .Offerlist-hghhfgfgfhgf{
    /* width: 60%; */
  }
  .offerlist-WhatShopexOffers{
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .Offerlist_outercover{
    width: 85%;
    display: flex;
    justify-content: center;
    /* flex-direction: column; */
    margin-left: 18%;
  }
  
  /* .offerlist-WhatShopexOffers {
    width: 70%;
  }

  .Offerlist-img-cover {
    display: contents;
  }

  .offerlist-WhatShopexOffers {
    display: contents;
  }

  .Offerlist-l1 {

  }
  .Offerlist-hghhfgfgfhgf {
    margin-left: 50px;
  } */
}

@media only screen and (max-width: 350px) {
  .Offerlist-img-cover {
    display: flex;
    flex-direction: column;
  }

  .offerlist-WhatShopexOffers {
    display: flex;
    /* flex-direction: column; */
    width: 90%;
    margin-left: 45px;
  }

  .Offerlist-l1 {
    padding-top: 50px;
  }
}

/* @media only screen and (max-width: 900px) {
  .Offerlist-img-cover{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
  }
} */