.shippingaddress {
    display: flex;
    flex-direction: column;
    border: none;
    outline: none;
    background: #FFFFFF;
    padding: 70px 0;
    display: flex;
    flex-direction: column;
    /* text-align: center; */
    gap: 20px;
    align-items: center;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.8rem;
    font-weight: 500;

    color: #C1C8E1;
}

/* .shippingaddress_body {
    display: grid;
    gap: 20px;
    /* border-bottom: 2px solid #8C9BD1; */
/* width: 50%;
}

.shippingaddress_body_name {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.shippingaddress_body_place {
    gap: 10px;
    display: flex;
    flex-direction: row;


} */
.shippingaddress_body {
    display: flex;
    flex-direction: column;
    width: 50%; 
}

.shippingaddress_body input {
    border: none;
    border-bottom: 1px solid #8C9BD1;
    outline: none;
    padding: 10px;
    margin-top: 10px;
    width: 100%;
    box-sizing: border-box;
} 
 
.shippingaddress_body_name_last{
    margin-left: 5%;
}
.shippingaddress_body_name {
    width: 100%; 
    display: flex;
    justify-content: space-between;
}
.shippingaddress_header {

    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 2rem;
    font-weight: 600;
    color: #FB2E86;
    /* padding-right: 63px; */
    width: 50%;
    padding-bottom: 40px;
}

/* .shippingaddress_body_name_first,
.shippingaddress_body_name_last,
.shippingaddress_body_place_country,
.shippingaddress_body_place_postalcode {
    width: 50%;
} */

/* .shippingaddress_body_name_first,
.shippingaddress_body_name_last,
.shippingaddress_body_address,
.shippingaddress_body_apartment,
.shippingaddress_body_city,
.shippingaddress_body_place_country,
.shippingaddress_body_place_postalcode {
    border-bottom: 1px solid #8C9BD1;
    padding-bottom: 10px;
} */

.shippingaddress_button button {
    background: #FB2E86;
    border-radius: 2px;
    border: none;
    outline: none;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.9rem;
    font-weight: 600;
    padding: 15px;
    color: #FFFFFF;
    width: 182px;
    /* height: 44.06px; */

}

.shippingaddress_button {
    padding-top: 40px;
}




/****************************************************/

@media only screen and (max-width: 500px) {
    /* .shippingaddress {
        padding-left: 0%;
        margin: 0;
    }

    .shippingaddress_body {
        width: 100%;
    } */
    .shippingaddress {
        display: block;
    }

    .shippingaddress_header {
        display: contents;
    }

    .shippingaddress_body {
        padding-top: 17px;
        display: flex;
        width: 100%;
    }
    .shippingaddress_button{
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 350px) {
    .shippingaddress {
        display: block;
    }

    .shippingaddress_header {
        display: contents;
    }

    .shippingaddress_body {
        padding-top: 17px;
        
    }
    .shippingaddress_button{
        display: flex;
        justify-content: center;
    }
}