.relatedproducts {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
}

.relatedproducts_list_image img {
    width: 110px;
    height: 110px;
}

.relatedproducts_list_image {
    padding: 0px 10px;
    display: flex;
    justify-content: center;
}

.relatedproducts_list_label_l1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* column-gap: 10px; */
    /* padding: 10px; */
}

.relatedproducts_list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    /* background-color: #FB2E86; */
    /* padding: 20px; */
    /* gap: 10px; */
    /* column-gap: 50px; */
    /* padding-right: 20px; */
    margin: 0px 50px;
}

.relatedproducts_list_label {
    width: 110%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.relatedproducts_header {

    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 2rem;
    font-weight: 650;
    color: #FB2E86;
    margin-right: 51%;
    padding-bottom: 20px;

}

.relatedproducts_cont {
    display: flex;
    flex-direction: row;

}


.relatedproducts_list_label_l1 {

    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
    color: #151875;
    display: flex;
    flex-direction: row;
    width: 100%;
}
.relatedproducts_list_label_l1_lab{
    color: #FB2E86;
    /* padding-right: 10px; */
}

.relatedproducts_list_label_l2 {

    color: #151875;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
}

.relatedproducts_list_label_l1 img {
    width: 39px;
    height: 6px;
}

.relatedproducts_list_label_l1_img {
    display: flex;
    text-align: end;
    margin-inline-start: auto;
}

.relatedproducts_list_label_l1_img {
    display: none;
}

@media only screen and (max-width:1500px){
    .relatedproducts_cont{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        width: 100%;
    }
    .relatedproducts_list{
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 50%;
        margin-top: 10px;
    }
}

@media only screen and (max-width:750px){
    .relatedproducts_cont{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        width: 100%;
    }
   
}

@media only screen and (max-width: 500px) {
    .relatedproducts {
        display: flex;
        width: 95%;
    }

    .relatedproducts_header {
        display: contents;
    }

    .relatedproducts_list_label_l1 {
        width: 120%;
        display: flex;
        flex-direction: column;
        text-align: center;
        /* column-gap: 15px; */
    }

    /* .relatedproducts_list_label_l1 label {
        width: 100%;
        display: flex;
        float: row;
    } */

    .relatedproducts_list_label {
        width: 80%;
        margin: 20px 0px;
        display: flex;
        justify-content: center;
    }

    .relatedproducts_list {
        margin: 0px 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }
    .relatedproducts_list_image{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-left: 10px;
    }

    .relatedproducts_cont {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        column-gap: 20px;
        margin-top: 20px;
        width: 90%;
    }
}
@media only screen and (max-width:360px){
    .relatedproducts{
        padding: 0;
    }
}

@media only screen and (max-width: 350px) {
    .relatedproducts {
        display: contents;
    }

    .relatedproducts_header {
        display: contents;
    }

    .relatedproducts_list_label {}
}

@media only screen and (max-width:290px){
    .relatedproducts_cont {
        display: flex;
        flex-direction: column;
        /* grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto; */
        column-gap: 20px;
        margin-top: 20px;
        width: 90%;
    }
    .relatedproducts_header{
        font-size: 1.5rem;
        font-weight: 620;
    }
}