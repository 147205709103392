.orderdetailpage_header{
    position: sticky;
    top:0;
}
.orderdetailpage_col {
    display: flex;
    margin-left: 20%;
}

.orderdetailpage_col_col1 {
    width: 70%;
}

.orderdetailpage_col_col2 {
    width: 10%;
}

.orderdetailpage_col_col2 {
    display: none;
}

/********************************************/

@media only screen and (max-width: 500px) {
    .orderdetailpage_col {
        display: flex;
        flex-direction: column;
    }

    .orderdetailpage_col_col2 {
        margin-left: -45px;
        padding-left: 0px;
    }

    .orderdetailspage_menubar_mobview {
        display: flex;
        position: absolute;
        margin-top: -220px;
        width: 100%;
    }
}

@media only screen and (max-width: 350px) {
    .orderdetailpage_col {
        /* display: flex; */
        /* width: min-content; */
    }

    .orderdetailpage_col_col2 {
        margin-left: -45px;
        padding-left: 0px;
    }

    .orderdetailspage_menubar_mobview {
        display: flex;
        position: absolute;
        margin-top: -240px;
        width: 100%;
    }
}