/* Slideshow */

.slideshow {
  margin: 0 auto;
  overflow: hidden;
  max-width: 100%;
  /* min-height:; */
  z-index: -1;
  /* background-position: center; */
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.slide {
  display: inline-block;
  /* height:700px; */
  width: 100%;
  border-radius: 3px;
  /* height: 500px; */
}
.slide img{
  width: 100%;
  height: 490px;

  /* height: 400px; */
}
/* Buttons */

.slideshowDots {
  text-align: center;
  /* margin-top: -50px; */
  /* position: absolute; */
  /* justify-items: center; */

}

.slideshowDot {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
  margin: 15px 7px 0px;

  background-color: #c4c4c4;
}

.slideshowDot.active {
  background-color: #ebb5cd;
}

@media only screen and (max-width: 900px) {

  .slideshow {
    width: 99.89%;
    margin-left: 0px;
  }
}

@media only screen and (max-width:820px){
  .slide img{
    height: 350px;
  }
}

@media only screen and (max-width:550px){
  .slide img{
    height: 245px;
  }
}

@media only screen and (max-width:500px) {
  .slide img {
    width: 100%;
    height: 245px;
  }
.slideshow{
  /* height: "window.screen.height-30"; */
}
.slideshowDot{
  width: 10px;
  height: 10px;
}
  .slideshowSlider {
    /* margin-bottom: -100px; */
  }
}
