.Orderlist {
    display: flex;
    /* flex-direction: column; */
    justify-items: center;
    padding: 50px 0;
}

.singleorderlist_outer {
    width: 100%;
    /* display: flex; */
    justify-content: center;
    display: flex;
}

.singleorderlist {
    /* width: 100%; */
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.singleorderlist_inner {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    width: 100%;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    /* margin-left: 15%; */
    padding: 10px;
}

.singleorderlist_img {
    padding: 0;
}

.singleorderlist_col {
    display: flex;
    flex-direction: column;
    padding-left: 22px;
    width: 60%;
}

.singleorderlist_col_row1 {
    padding-top: 33px;
    display: flex;
    flex-direction: column;
}

.singleorderlist_col_label1 {
    font-weight: 650;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1rem;
    padding-bottom: 5px;
    color: #FB2E86;
}

.singleorderlist_col_label2 {
    font-size: .9rem;
    font-weight: 550;
    padding-bottom: 10px;
    color: #5a2efb;
}

.singleorderlist_col_row2 {
    padding: 10px 0;
}

.singleorderlist_col_row3_lab {
    padding-left: 90px;
}

.singleorderlist_col_row2_lab1 {

    padding-right: 42px;
    /* background-color: blue; */
}





/***************************************/

@media only screen and (max-width:550px){
    .singleorderlist_img img{
        width: 150px;
        height: 150px;
    }
}

@media only screen and (max-width: 500px) {
    /* .Orderlist_outer-cover {
        margin: 20px 0;
        padding: 0;
    }

    .singleorderlist {
        padding: 0;
        margin-top: 20px;
    }

    .singleorderlist_img img {
        max-width: 150px;
    }

    .singleorderlist_col_row2_lab1 {
        padding: 0;
    }

    .singleorderlist_col_row2_lab2 {
        padding-left: 20px;
    }

    .singleorderlist_col_row3_lab {
        padding: 0;
        padding-left: 70px;
    } */
    
    .singleorderlist_inner {
        /* background-color: yellow; */
        display: flex;
        flex-direction: column;
        width: 92%;
        /* width: min-content; */
    }

    .singleorderlist_col {
        width: 100%;
    }

    .singleorderlist_img {
        /* width: 100px; */
        display: flex;
        justify-content: center;
    }

}

@media only screen and (max-width: 350px) {
    .singleorderlist_inner {
        /* background-color: yellow; */
        display: flex;
        flex-direction: column;
        /* width: min-content; */
    }

    .singleorderlist_col {
        width: 100%;
    }

    .singleorderlist_img {
        /* width: 100px; */
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width:290px){
    .singleorderlist_col {
        margin-left: -15px;
    }
}