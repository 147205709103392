.ordercompletedpage_menubar_mobview {
    display: none;
}
.ordercompletedpage_header{
    position: sticky;
    top:0;
    background-color: #c90053cf;
}

@media only screen and (max-width: 500px) {
    .ordercompletedpage_menubar_mobview {
        display: flex;
        position: absolute;
        margin-top: -220px;
        width: 100%;
    }
}

@media only screen and (max-width: 350px) {
    .ordercompletedpage_menubar_mobview {
        display: flex;
        position: absolute;
        margin-top: -240px;
        width: 100%;
    }
}

@media only screen and(max-width:290px){
    
}