.whatsapp{
    display: flex;
    justify-content: right;
    position: sticky;
    bottom: 0;
}
.whatsapp_inner{
    width: 70px;
    height: 50px;
    border-radius: 40%;
    /* background-color: #ff1788; */
    background-color: green;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}