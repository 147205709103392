.loginpage_logsign {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    /* background-color: yellow; */
}
.loginpage{
    position: sticky;
    top:0;
}


/**************************************************/

@media only screen and (max-width:1500px){
    .loginpage_logsign{
        display: flex;
        /* flex-direction: column; */
        justify-content: center;
        justify-items: center;      
        /* background-color: yellow; */
        padding-top: 30px;
    }
    .loginpage_logsign_login{
        width: 100%;
        display: flex;
        justify-content: center;
        padding-left: 0px;
    }
    .loginpage_logsign_signup{
        /* background-color: green; */
        width:100%;
        display: flex;
        justify-content: center;
        padding-right: 0px;
    }
}

@media only screen and (max-width:1280px){
    .loginpage_logsign{
        display: flex;
        justify-content: center;
    }
}
@media only screen and (max-width: 500px) {
    .loginpage_logsign {
        display: flex;
        /* flex-direction: column; */
        justify-content: center;
        width: 100%;
    }

    .loginpage_logsign_login {
        padding: 0;
        display: flex;
        justify-content: center;
        margin: 0;
        width: 100%;
    }

    .loginpage_logsign_signup {
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        width: 100%;
        /* margin-left: 20px; */
    }

    .loginpage_menubar_mobview {
        display: flex;
        position: absolute;
        margin-top: -220px;
        width: 100%;
    }
}

@media only screen and (max-width: 350px) {
    .loginpage_menubar_mobview {
        display: flex;
        position: absolute;
        margin-top: -240px;
        width: 100%;
    }
}

/* @media only screen and (max-width: 900px) {
.loginpage_logsign{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.loginpage_logsign_login{
    display: flex;
width: 63%;
}
.loginpage_logsign_signup{
    width: 72.65%;
    padding-left: 100px;
    margin-right: 50px;
}
} */