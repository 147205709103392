.Header {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #c90053cf;
  /* overflow: hidden; */
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.Header_r1 {
  background-color: #c90053cf;
  /* padding: 10px 5% 0px 5%; */
  padding: 0px 5% 0px 5%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  justify-content: space-between;
  margin-bottom: -10px;
}

.Header_r1_left {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: right;
  padding-top: 5px;
}
.Header_r2_col1_logo{
  margin-top: -20px;
}
/* .Header_r2_col1_logo img{
  width:250px;
} */

.Header_r1_left_mailid {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
}
.Header_r1_left_mailid_img{
  padding-top:5px;
}

.Header_r1_left_mailid_mail {
  /* padding-left: 10px; */
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Header_r1_left_contact_img{
  padding-top: 5px;
  color: white;
  font-size: 15px;
}
.Header_r1_left_contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
  padding-left: 50px;
  padding-bottom: 15px;
}

.Header_r1_left_contact_number {
  /* padding-left: 10px; */
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Header_r1_left_contact_number label {
  color: white;
}

.Header_r1_right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 45px;
  align-items: center;
}

.Header_r1_right_1 select {
  background-color: #dd2672cf;
  border: none;
  color: white;
}

.Header_r1_right_2 select {
  background-color: #dd2672cf;
  border: none;
  color: white;
}


.Header_r1_right_3 {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.Header_r1_right_3_img{
  display: flex;
  justify-content: center;
}
.Header_r1_right_4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* width: 100%; */
  /* background-color: yellow; */
}
.Header_r1_right_4_img{
  display: flex;
  justify-content: center;
}
.Header_r1_right_5_span{
  display: flex;
  justify-content: center;
}

.Header_r1_right_4_wishlist label {
  color: white;
  padding-right: 5px;
}

.Header_r1_right_5 {
  display: flex;
  flex-direction: column;
  color: white;
}

.Header_r1_right_5_count {
  color: white;
  font-weight: 550;
  padding-left: 5px;
  position: absolute;
  /* background-color: rgb(162, 18, 18); */
  width: 16;
  height: 16;
  border-radius: 50%;
  /* padding-right: 10; */
  /* padding-top: +10; */
  margin-left: 15px;
  margin-top: -10px;
  align-items: center;
  justify-content: right;
  display: flex;
}

.Header_r2 {
  background-color: #c90053cf;
  display: flex;
  flex-direction: row;
  /* padding: 0px 5% 10px 5%; */
    padding: 0px 5% 0px 5%;
  justify-content: space-between;
  align-items: center;
}

.Header_r2_col1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: white;
  align-items: center;
  width: 20%;
}

.Header_r2_col1 label {
  display: flex;
  flex-direction: row;
  /* font-weight: bold;
  font-family: Josefin Sans; */
  font-size: 2rem;
  /* text-shadow: 1px 1px; */
}

.Header_r2_col2_outer_inner{
width: 150%;
display: flex;
justify-content: center;
}

.Header_r2_col2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  color: white;
  width: 50%;
  padding-top: 15px;
}

.Header_r2_col2 li {
  padding-left: 25px;
  padding-right: 25px;
  margin: 0;
  font-size: 0.9rem;
  list-style-type: none;
  color: white;
}

.Header_r2_col2 li:hover {
  font-weight: bold;
  color: #7e33e0;
}

.Header_r2_col2 select {
  border: none;
  color: rgb(214, 50, 78);
}

.Header_r2_col2 button {
  border: none;
  background-color: white;
}

.Header_r2_col3 {
  display: none;
  /* display: flex; */
  flex-direction: row;
  justify-content: flex-end;
}

.Header_r2_col3 div {
  background-color: #fb2e86;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Header_r2_col3 input {
  border-width: 1px;
  border-color: #fb2e86;
  border-style: solid;
  padding: 5px 10px;
  outline: none;
}

.Header_r2_col3 img {
  padding: 0 8px;
}

.header_mobview,
.header_mobview1,
.header_mobview_outer {
  display: none;
  align-items: center;
  padding-left: 10px;
}




.Header_r1_right_login_button {
  /* background-color: #dd2672cf; */
  border: none;
  color: white;
  /* font-weight: 600; */
  padding-right: 5px;
}
.Header_r1_right_login_button_bold{
  color: white;
  padding-right: 5px;
  font-weight: 700;
}

.Header_r1_right_login_button:hover~.loginpopup {
  display: flex;
  flex-direction: column;
  z-index: 1px;
  position: absolute;
  justify-content: center;
}

.Header_r1_right_3:hover {
  display: flex;
}

.loginpopup:hover {
  display: flex;
}

.loginpopup {
  display: none;
  flex-direction: column;
  margin-top: 2px;
  z-index: 1px;
  position: absolute;
  background-color: white;
  width: 8%;
  padding: 10px 0px;
  border: 1px solid silver;
  margin-left: -2.5%;
  justify-content: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: .85rem;
  /* width: 8%;
display: none;
  flex-direction: column;
border: 1px solid gray;
  padding: 10px 0px;
  background-color: white;
  z-index: 1px;
  position: absolute;
  justify-content: left;
  color: black;
  font-weight: normal;
  display: flex;
  margin-left: -20px; */
}

.loginpopup label {
  display: flex;
  /* margin-left: 50px; */
  justify-content: center;
}

.loginpopup_hide {
  display: none;
}

.menubar_title:hover~.loginpopup {
  display: flex;
  flex-direction: column;
  z-index: 1px;
  position: absolute;
  margin-top: 40px;
  color: white;
  justify-content: center;
}

.loginpopup_menu {
  display: flex;
  border-bottom: 2px solid rgb(207, 201, 168);
  width: 85%;
  margin-left: 10px;
  justify-content: center;
  padding: 10px 0px;
}

.loginpopup_menu label {}
.Header_r2_col2_extra{
  width: 40%;
  display: flex;
}
.Header_r2_col2_outer{
  width: 80%;
  display: flex;

}



@media only screen and (max-width:1500px){
  .Header{
    width:100%;
  }
  
  .Header_r2_col1_logo img{
    /* max-Width:150px */
  }
.Header_r2_col1_logo{
  margin-left:20px;
}
.Header_r1_left_mailid{
  padding-left: 60px;
}
.Header_r1_left_contact{
  padding-left: 0px;
}
/* .Header_r2 {
  padding: 0px 1% 0px 1%;
} */
/* .Header_r2_col2{
  display: none;
} */
}

@media only screen and (max-width:900px){
  .Header_r1{
    justify-content: right;
  }
  .Header_r2{
    padding: 0px 2% 10px 7%;
  }
  .Header_r1_left_mailid{
    /* padding-left: 60px; */
  }
  .Header_r1_left_contact{
    padding-left: 0px;
  }
  .Header_r2_col2_outer_inner{
    margin-left: -50px;
  }
  .Header_r2_col2 li {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  /* .Header_r2_col2{
    width: 100%;
  } */
  /* .Header_r2_col3{
    display: flex;
    width: 20%;
  } */
  /* .Header_r1_left{
    
    padding-top: 0px;
  } */
  /* .header_row3{
    display: flex;
    justify-content: right;
background-color: #fb2e86; 
margin-top: -10px;
 } */
 
  /* .Header_r1_left_contact_number{
    display: none;
  } */
  /* .Header_r1_left{
    padding-top: 50px;
    position: absolute;
    margin-left: 50%;
  } */
}
@media only screen and (max-width:850px){

  .Header_r2_col1_logo img{
    max-Width:180px
  }
  
}
@media only screen and (max-width:750px){
  .Header_r2_col1_logo img{
    max-Width:170px
  }
  .Header_r2_col1_logo{
    margin-left: -10px;
  }
}
@media only screen and (max-width:580px){
  .Header_r1_right {
    gap: 15px;
  }
  .Header_r2_col1_logo img{
    max-width: 150px;
  }
}

@media only screen and (max-width: 500px) {
  .Header {
    width: 100%;
    display:flex;
    flex-direction:column;
    /* margin-left: 10px; */
  }

  .Header_r2_col1_logo{
    margin-top: 1px;
  }
  .Header_r2_col1_logo img{
    max-width: 120px;
    /* max-height: 250px; */
  }
  .Header_r2_col1 img {
    width: 30px;
    height: 30px;
  }

  .Header_r1_left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding-top: 20px;
    /* margin-left: -180px; */
    /* background-color: yellow; */
  } 
  .Header_r1_left_mailid{
    justify-content: center;
    padding-left: 0px;
  }
  .Header_r1_left_contact{
    justify-content: center;
    padding-left: 0px;
    margin-top: -5px;
    /* padding-top: 0px; */
  }

  .loginpopup_menu {
    display: flex;
    border-bottom: 2px solid rgb(207, 201, 168);
    width: 95%;
    margin-left: 10px;
    justify-content: center;
    padding: 10px 0px;
  }

  .loginpopup_menu label {
    margin-left: -65px;
    padding: 5px 0px;
  }

  .header_mobview_login:hover {
    display: flex;
  }

  .header_mobview_login img:hover~.loginpopup {
    display: flex;
    flex-direction: column;
    z-index: 1px;
    position: absolute;
    justify-content: center;
  }

  .loginpopup:hover {
    display: flex;
    justify-content: center;

  }

  .loginpopup {
    display: none;
    flex-direction: column;
    margin-top: 40px;
    z-index: 1px;
    position: absolute;
    background-color: white;
    width: 99.6%;
    padding: 10px 0px;
    border: 1px solid silver;
    margin-left: -61%;
    /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: .6rem; */
  }

  .loginpopup label {
    font-size: .85rem;
    color: black;
    font-weight: 650;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

  }

  .Header_r1_right_5_count_mobview {
    position: absolute;
    margin-top: -21px;
    margin-left: 20px;
  }

  .header_mobview,
  .header_mobview1 {
    display: flex;
  }
  .header_mobview_outer{
    display: flex;
    margin-left: -10px;
  }

  .Header_r1 {
    display: none;
  }

  .Header_r2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* background-color: #fb2e86; */
    background-color: #c90053cf;
    justify-content: center;
    padding: 10px 0px;
    /* margin-left: -15px; */
  }

  .Header_r2_col1 {
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 10px;
    margin-left: -10px;
  }

  .Header_r2_col1 label {
    padding-left: 90px;
  }

  .Header_r2_col2_outer {
    /* display: block; */
    display: flex;
    flex-direction: column;
  }
  .Header_r2_col2_mobview{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    /* margin-left: 70px; */
    color: white;
    width: 100%;
    padding-top: 15px;
  }
  
.Header_r2_col2_mobview li {
  padding-left: 25px;
  padding-right: 25px;
  margin: 0;
  font-size: 0.9rem;
  list-style-type: none;
  color: white;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left:40%;
}

.Header_r2_col2_mobview li:hover {
  font-weight: bold;
  color: #7e33e0;
}

  .Header_r2_col2 {
    display: none;
    flex-direction: column;
    /* width: 60%; */
    /* display: none; */
  }


  .Header_r2_col3 {
    display: none;
    width: 100%;
    /* background-color: yellow; */
    justify-content: center;
  }

  .Header_r2_col2 button {
    background-color: transparent;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .Header_r2_col3 input {
    background-color: transparent;
  }

  
  /* .header_mobview1 :hover .Header_r2_col2_outer{
    display: block;
   } */
  .headermenu {
    display: none;
  }
  .Header_r2_col2_outer_inner{
    margin-left: -50px;
    width: 60%;
  }

  .popup_title_goldrate {
    display: flex;
    flex-direction: column;
    top: 60%;
    z-index: 1;
    position: absolute;
    background-color:white;
    width: 92%;
    padding-top: 20px;
    padding-bottom: 30px;
    border: 1px solid silver;
    margin-left: -54.5%;
    row-gap: 20px;
    justify-content: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: .85rem;
  padding-left: 15px;
  padding-right: 20px;
  margin-top: 40px;
  }
  
  .popup_title_goldrate label {
    display: flex;
    justify-content: center;
    color: black;
  }
  
  .popup_title:hover~.menubar_title_goldrate {
    display: flex;
    flex-direction: column;
    z-index: 1px;
    position: absolute;
    top: 20%;
    color: black;
    justify-content: center;
  }
  
  .popup_title_goldrate_row1 {
    display: flex;
    justify-content: center;
    border-bottom: 2px solid rgb(207, 201, 168);
    width: 100%;
    /* margin-left: 15px; */
    padding-bottom: 10px;
  }
  
  .popup_title_goldrate_row1 span {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .Header_r1_right{
    display: none;
  }
  .Header_r2_col2_mobview_menu{
    display: flex;
    justify-content: center;
    margin-left: -47px;
    /* top: 150px; */
  }
  
}

.Header_r1_left_mailid_img img {
  padding-right: 0;
}

.Header_r1_left_contact_img img {
  padding-right: 0;
}

/* @media only screen and (max-width: 350px) {

  .header_mobview,
  .header_mobview1 {
    display: flex;
  }

  .Header_r1 {
    display: none;
  }

  .Header_r2 {
    display: flex;
    flex-direction: column;
    background-color: #fb2e86;
    justify-content: center;
    padding: 10px 10px;
  }
 
  .Header_r2_col2 {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .Header_r2_col1 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 10px;
  }
  
  .Header_r2_col2_line{
    display: none;
  }
  .Header_r2_col1 label {
    padding-left: 90px;
  }
  .header_mobview img{

  }

  .Header_r2_col3 {
    width: 100%;
    justify-content: center;
  }

  .Header_r2_col2 button {
    background-color: transparent;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .Header_r2_col3 input {
    background-color: white;
  }

  .Header_r2_col1 img {
    width: fit-content;
    height: fit-content;
  }
 .header_mobview1{
  width: fit-content;
 }

    .headermenu {
    display: none;
  }
  .Header_r2_col3{
    display: none;
  }
  .menubar_ul{
    display: flex;
    flex-direction: column;
    width: fit-content;
  }
  

} */
@media only screen and (max-width:420px){
  .popup_title_goldrate {
    margin-top: 37px;
    width: 90%;
    margin-left: -54.90%;
  }

}

@media only screen and (max-width:380px){
  .popup_title_goldrate{
    margin-left: -55.99%;
    width: 90%;
    padding-left: 15px;
  }
  
}
@media only screen and (max-width: 350px) {

  .header_mobview,
  .header_mobview1 {
    display: flex;
  }

  .Header_r1 {
    display: none;
  }

  .Header_r2 {
    display: flex;
    flex-direction: column;
    background-color: #fb2e86;
    justify-content: center;
    padding: 10px 10px;
  }

  .Header_r2_col1 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  .Header_r2_col1 label {
    padding-left: 90px;
  }

  .Header_r2_col2_outer {
    display: block;
  }

  .Header_r2_col2 {
    display: none;
    flex-direction: column;
    width: 100%;
    /* display: none; */
  }


  .Header_r2_col3 {
    display: none;
    width: 100%;
    justify-content: center;
  }

  .Header_r2_col2 button {
    background-color: transparent;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .Header_r2_col3 input {
    background-color: transparent;
  }

  .Header_r2_col1 img {
    width: 30px;
    height: 30px;
  }

  /* .header_mobview1 :hover .Header_r2_col2_outer{
    display: block;
   } */
  .headermenu {
    display: none;
  }
  .Header{
    width: 94%;
    display: flex;
    position: sticky;
    top: 0;
  }
}

@media only screen and (max-width:290px){
  .popup_title_goldrate{
    margin-left: -57.99%;
    /* width: 90%;
    padding-left: 15px; */
  }
  .Header{
    width: 100%;
  }
  .Header_r2{
    padding: 10px 1px;
  }
  .Header_r2_col1{
    width: 98%;
  }
  .header_mobview_outer{
    margin-left: -15px;
  }
}

/* @media only screen and (max-width: 900px) {
  .Header_r2 {
    padding: 0px 0% 10px 2px;
  }
  .Header_r2_col1_logo img{
    max-Width:150px
  }

  .Header_r2_col3 input {
    padding: 5px 0px;
    width: 70%;
  }

  .Header_r2_col3 {
    padding-right: 5px;
  }

  .Header {
    width: 120%;
    display:flex;
    flex-direction:column;
    
  }
  .Header_r1_left_contact{
    padding-left:20px;
  }
  .Header_r1_left_mailid {
    margin-left:-15px;
  }
} */

