.orderlistpage_menubar_mobview {
    display: none;
}

.orderlistpage_header{
    position: sticky;
    top: 0;
}

@media only screen and (max-width: 500px) {
    .orderlistpage_menubar_mobview {
        display: flex;
        position: absolute;
        margin-top: -220px;
        width: 100%;
    }
}

@media only screen and (max-width: 350px) {
    .orderlistpage_menubar_mobview {
        display: flex;
        position: absolute;
        margin-top: -240px;
        width: 100%;
    }
}