.AddressList {
  justify-content: center;
  display: flex;
}
.AddressList_body {
  display: flex;
  padding-top: 60px;
  flex-direction: column;
  justify-content: center;
  width: 40%;
}

.AddressList_row1 {
  display: flex;
  padding: 40px 0;
  /* justify-content: space-between; */
}
.singleaddresslist_col1 {
  display: flex;
  /* justify-content: center; */
}

.AddressList_row3_input {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  outline: none;
}

.singleaddresslist {
  display: flex;
  padding-bottom: 20px;
  border-bottom: 1px solid silver;
  /* justify-content: space-between; */
  margin-bottom: 20px;
}

.singleaddresslist_col1 {
  display: flex;
}

.singleaddresslist_col2 {
  display: flex;
  flex-direction: column;
}
.AddressList_row3 {
  display: flex;
  align-items: center;
}

.AddressList_row1_col1_lab {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 650;
  color: darkblue;
  font-size: 1rem;
}
.singleaddresslist_col2 label {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.85rem;
  font-weight: 500;
}
/********************/

.AddressList_row1_col1,
.singleaddresslist_col1 {
  width: 10%;
}

.AddressList_row1_col2,
.singleaddresslist_col2 {
  width: 30%;
}

.AddressList_row1_col3,
.singleaddresslist_col3 {
  width: 30%;
}

.AddressList_row1_col4,
.singleaddresslist_col4 {
  width: 30%;
}


/******************************************************/

@media only screen and (max-width: 500px) {
    .AddressList_body{
        /* width: 80%; */
        width: -webkit-fill-available;
    }
}
@media only screen and (max-width: 350px) {
  .AddressList_body {
    width: -webkit-fill-available;
  }
}
