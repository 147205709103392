.Topcategories {
  padding-bottom: 50px;
}

.Topcategories_header {
  display: flex;
  justify-content: center;
  /* font-size: 20px; */
  color: #fb2e86;
  font-weight: bold;
  margin-top: 50px;
}

.Topcategories_header label {
  font-size: 2rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 650;
}

.Topcategories_cont {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 50px;
}

/* .Topcategories_list{
    display: flex;
    flex-direction: column;
} */
.Topcategories_list_img {
  background: #fcf8f8;
  text-align: center;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Topcategories_list_img img {
  width: 750px;
  height: 750px;
}

.Topcategories_list_img:hover .Topcategories_list_img_button {
  display: block;
}

.Topcategories_list_img:hover {
  /* box-shadow: 5px 0px 3px 3px rgb(134, 133, 167); */
  /* box-shadow: 0px 8px 40px rgba(49, 32, 138, 0.05); */
  /* box-shadow: 3px 3px 3px 3px #965fee; */
  box-shadow: 3px 3px 3px 3px #ed73a8;
}

.Topcategories_list img {
  height: 125px;
  width: 125px;
}

.Topcategories_list_img_button {
  background: #c90000;
  border-radius: 2px;
  border: none;
  outline: none;
  justify-content: center;
  color: white;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  padding: 5px;
  width: 94px;
  height: 29px;
  display: none;
}

.Topcategories_list_label {
  display: flex;
  flex-direction: column;
  /* Text */
  padding-top: 10px;
  color: #fb2e86;
  text-align: center;
  font-weight: 650;
}

.Topcategories_list_label_l1 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.9rem;
  font-weight: 650;
  line-height: 23px;
}

.Topcategories_list_label_l2 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  padding-top: 1px;

}
.Topcategories_list_label_l3{
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.8rem;
color: #151875;
padding-top: 1px;
}

/******slid***********/
.Topcategories_footer {
  justify-content: center;
  padding-top: 50px;
  display: flex;
}

.Topcategories_footer img {
  width: 50px;
}

.Topcategories_footer_slider {
  height: 4px;
  width: 20px;
  background-color: #f8aacc;
  border-radius: 20px;
  margin: 0 5px;
  cursor: pointer;
}

.topcategories_loader {
  position: absolute;
  margin-left: 48%;
  margin-top: 110px;
}
@media only screen and (max-width:900px){
  /* .Topcategories_cont {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
  } */
  .Topcategories_list_img_outer{
    display: flex;
    justify-content: center;
  }
  .Topcategories_list_img {
    width: 130px;
    height: 130px;
    border-radius: 50%;
  }
  
  .Topcategories_list_img img {
    width: 80px;
    height: 80px;
  }
  
}

@media only screen and (max-width:650px){
  .Topcategories_list_img {
    width: 110px;
    height: 110px;
    border-radius: 50%;
  }
  
  .Topcategories_list_img img {
    width: 70px;
    height: 70px;
  }
}
@media only screen and (max-width:550px){
  .Topcategories_list_img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  
  .Topcategories_list_img img {
    width: 70px;
    height: 70px;
  }
}


@media only screen and (max-width: 500px) {
  .Topcategories_cont {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 20px;
  }

  .Topcategories {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background-color: yellow; */
    margin-left: 20px;
    padding-bottom: 0px;
    /* width: 70%; */
  }

  .Topcategories_list_label {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    text-align: center;
    /* font-weight: 650; */
  }

  .Topcategories_list_img {
    width: 230px;
    height: 230px;
  }
.Topcategories_list_img img{
  width: 150px;
  height: 150px;
}
  .Topcategories_footer {
    padding-bottom: 50px;
  }
  .Topcategories_list{
    padding-bottom: 15px;
  }

}

@media only screen and (max-width: 350px) {
  .Topcategories_cont {
    display: grid;
    gap: 20px;
  }

  .Topcategories {
    display: flex;
    flex-direction: column;
    /* width: 70%; */
  }

  .Topcategories_footer {
    padding-bottom: 50px;
  }

}

/* @media only screen and (max-width: 900px) {
  .Topcategories_cont {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
  }

  .Topcategories {
    width: 92%;
    margin-left: 35px;
  }
} */