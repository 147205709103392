.wishlistpage_menubar_mobview{
    display: none;
}
.wishlistpage_wishcomponent{
    width: 80%;
    margin-left: 16%;
}
.wishlistpage_header{
    position: sticky;
    top: 0;
    background-color: #c90053cf;
}
@media only screen and (max-width: 500px) {
    .wishlistpage_menubar_mobview{
        display: flex;
        position: absolute;
        margin-top:-240px ;
        width: 100%;
    }
}
@media only screen and (max-width: 350px) {
    .wishlistpage_menubar_mobview{
        display: flex;
        position: absolute;
        margin-top:-240px ;
        width: 100%;
    }
}
