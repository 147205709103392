.forgotpassword{
    display: flex;
    flex-direction: row;
    padding-bottom: 60px;
}
.forgotpassword_row1{
    width: 100%;
    display: flex;
    justify-content: center;
}

.otp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 400px; */
    width: 30%;
    /* margin-left: 100px; */
    margin-top: 60px;
    padding: 20px 50px;
    background-color: white;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);

}

.otp_header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* padding-bottom: 20px; */
    /* font-size: 1.8rem; */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
}

.otp_header h2 {
    font-size: 1.8rem;
    color: #FB2E86;
}

.otp_header2 {
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #9096B2;
    font-size: .9rem;
}

.otp_inner {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;

}

.otp_inner input {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #C2C5E1;
    color: #9096B2;
    border-radius: 2px;
    outline: none;
}
.otp_inner_lab{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: .8rem;
    display: flex;
    justify-content: left;
    color: red;
}
.otp_button {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 25px;
    border: none;

}

.otp_button button {
    width: 100%;
    color: white;
    background-color: #FB2E86;
    border: none;
    outline: none;
    padding: 10px 0;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 3px;
}

.otp_popup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 50px;
    /* font-weight: 500; */
}

.otp_popup label {
    font-size: .9rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #9096B2;
}





/*************************************************/

@media only screen and (max-width:1500px){
    .otp{
        width: 45%;
        margin-left: 0px;
    }
}

@media only screen and (max-width: 500px) {
    .otp {
        margin: 0;
        /* margin-left: 10px; */
        display: flex;
        width: 71%;
    }
}

@media only screen and (max-width: 350px) {
    .otp {
        display: flex;
        /* padding-left: 30px; */
        margin-left: 1px;
        width: 71%;
    }

}

@media only screen and (max-width:290px){
    .otp_header h2{
        font-size: 1.4rem;
    }
}