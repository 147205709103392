.featuredlist {
  background-color: #f5f5f5;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  width: 100%;
}
.feturedproduct_loader{
  position: absolute;
  margin-left: 48%;
  margin-top: 90px;
}

.featurelist_outer {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: white;
  align-items: center;
  margin-top: 2%;
  margin-left: 0%;
  padding-left: 0%;
}

.featurelist_outer h1 {
  color: #fb2e86;
  font-size: 2rem;
  font-weight: 650;

}

.featuredlist_outer_inner {
  /* width: 70%; */
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin-left: 0px;
  padding-left: 0;
}

.singlefeatredlist_outer {
  display: flex;
}

.singlefeatredlist {
  /* padding-top: 10px; */
  width: 270px;
  background-color: white;
  margin: 10px;
  padding-bottom: 10px;
  height: 361px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.singlefeatredlist:hover {
  background-color: #fb2e86;
}

.singlefeatredlist h4 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #fb2e86;
  font-size: 1.2rem;
  font-weight: 500;
  /* padding-top: 5px; */
  margin: 0px;
  padding-top: 10px;
}

.singlefeatredlist:hover h4 {
  color: white;
}

.singlefeatredlist label {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  font-size: 0.85rem;
  font-weight: 500;
  color: #151875;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.singlefeatredlist:hover label {
  color: white;
}

.singlefeatredlist_inner {
  padding-top: 10px;
  background-color: white;
  height: 246px;
}

.singlefeatredlist_inner_outer {
  height: 30px;
}

.singlefeatredlist_inner_topimg {
  display: flex;
  padding-left: 10px;
  display: none;
  height: 30px;
}

.singlefeatredlist_inner_topimg_top {
  padding-right: 10px;
  align-items: center;
  display: flex;
}

.singlefeatredlist:hover .singlefeatredlist_inner_topimg {
  display: block;
  display: flex;
}

.singlefeatredlist:hover .singlefeatredlist_inner_topimg_1 {
  display: block;
  /* padding-right: 10px; */
  /* justify-content: center; */
}

.singlefeatredlist:hover .singlefeatredlist_inner_topimg_2 {
  display: block;
  /* padding-left: 10px; */
}

.singlefeatredlist:hover .singlefeatredlist_inner_topimg_3 {
  display: block;
  /* padding-left:  10px; */
}

.singlefeatredlist_inner_img {
  justify-content: center;
  display: flex;
  padding-bottom: 10px;
}

.singlefeatredlist_inner_img img {
  width: 250px;
  height: 150px;
}

.singlefeatredlist:hover .singlefeatredlist_barimg {
  display: none;
}

.singlefeatredlist_h_barimg {
  display: none;
  align-content: center;
  /* display: flex; */
}

.singlefeatredlist:hover .singlefeatredlist_h_barimg {
  display: block;
}

.singlefeatredlist_3bar {
  padding-top: 10px;
  justify-content: center;
  display: flex;
}

.singlefeatredlist_inner_button {
  display: none;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
}

.singlefeatredlist:hover .singlefeatredlist_inner_button {
  display: block;
  /* padding-top: 10px; */
}

.singlefeatredlist_inner_button button {
  width: 94px;
  padding: 8px;
  border-radius: 2px;
  background-color: #ce3883;
  color: white;
  border: none;
  outline: none;
}

.singlefeaturelistcircle {
  display: flex;
  width: 31px;
  height: 31px;
  background-color: rgb(0, 0, 0) transparent;
  border-radius: 50%;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.singlefeatredlist:hover .singlefeaturelistcircle {
  display: flex;
  background-color: #e9e9f4;
  justify-content: right;
  /* align-items: center; */
}

/*****************pagination****************/
.featurelist_outer_pagination {
  display: flex;
  padding-top: 10px;
}

.featurelist_outer_img_button {
  height: 4px;
  width: 20px;
  background-color: #f8aacc;
  border-radius: 20px;
  margin: 0 5px;
  cursor: pointer;
}

@media only screen and (max-Width:1200px){
  .feturedlist_cards{
    width:50%;
    
    /* grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto; */
    /* justify-content:center; */
  }
  .featurelist_outer{
    margin-left:-10px
  }
  /* .featuredlist_outer_inner{
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto; 
  } */
  .singlefeatredlist_outer{
    width:40%;
  }
  .singlefeatredlist_outer{
    width: 100%;
  }
  .singlefeatredlist_inner{
height: 180px;
  }
  .singlefeatredlist_inner_img img{
    height: 100px;
    width: 100px;
  }
  .singlefeatredlist{
    width: 220px;
    height: 300px;
  }
}

@media only screen and (max-width:1100px){
  .singlefeatredlist_inner{
    height: 180px;
      }
      .singlefeatredlist_inner_img img{
        height: 100px;
        width: 100px;
      }
      .singlefeatredlist{
        width: 190px;
        height: 300px;
      }
}

@media only screen and (max-width:900px) {
  .featurelist_outer{
    width:100%;
    /* margin-left:15.799%; */
    
  }
  .singlefeatredlist_inner{
    height: 140px;
      }
      .singlefeatredlist_inner_img img{
        height: 90px;
        width: 100px;
      }
      .singlefeatredlist{
        width: 160px;
        height: 260px;
      } 
}

@media only screen and (max-width:750px){
  .singlefeatredlist_inner{
    height: 140px;
      }
      .singlefeatredlist_inner_img img{
        height: 90px;
        width: 100px;
      }
      .singlefeatredlist{
        width: 130px;
        height: 250px;
      } 
      .singlefeatredlist h4 {
        font-size: .9rem;
      }
      .singlefeatredlist label {
        font-size: 0.75rem;
      }
}

@media only screen and (max-width:650px){
  .singlefeatredlist_inner{
    height: 120px;
      }
      .singlefeatredlist_inner_img img{
        height: 80px;
        width: 100px;
      }
      .singlefeatredlist{
        width: 120px;
        height: 230px;
      } 
      .singlefeatredlist h4 {
        font-size: .9rem;
      }
      .singlefeatredlist label {
        font-size: 0.75rem;
      }
}

@media only screen and (max-width:575px){
  .singlefeatredlist{
    width: 120px;
    height: 220px;
  } 
  .singlefeatredlist h4 {
    font-size: .8rem;
  }
  .singlefeatredlist label {
    font-size: 0.7rem;
  }
  .featurelist_outer{
    width: 100%;
    /* padding-left: 15px; */
  }
  .featuredlist_outer_inner{
    margin-left: 20px;
    padding: 0px;
  }
  .singlefeatredlist_outer{
    margin: 0px;
  }
  .singlefeatredlist{
    margin: 5px;
  }
}

@media only screen and (max-width:500px) {
  .featuredlist {
    /* width: 70%; */
  }
  .featurelist_outer{
    margin-left: 0px;
    /* width: 80%; */
  }
  .singlefeatredlist_outer {
    width: 100%;
  }
  .feturedlist_cards{
    display:grid;
    flex-direction:column;
        grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto; 

  }
  .featuredlist_outer_inner{
    display:flex;
    flex-direction:column;
    margin-left: 0px;
    /* grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;  */
  }
  .singlefeatredlist_inner_img img{
    width: 250px;
    height: 200px;
  }
  /* .featuredlist_outer_inner {
    display: contents;
  } */
  .singlefeatredlist_inner_button{
    display: none;
  }
  .singlefeatredlist {
    /* padding-top: 10px; */
    width: 250%;
    background-color: white;
    margin: 10px;
    padding-bottom: 10px;
    height: 361px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  }
  
  .singlefeatredlist h4 {
    font-size: 1.2rem;
  }
  .singlefeatredlist label {
    font-size: 0.85rem; 
  }
  .singlefeatredlist_inner {
    padding-top: 10px;
    background-color: white;
    height: 246px;
  }
  
}

@media only screen and (max-width:350px) {
  .featuredlist {
    /* width: 70%; */
  }

  .featuredlist_outer_inner {
    display: contents;
  }
  .feturedproduct_loader{
    margin-top: 70px;
  }
  
  /* .featurelist_outer h1{
    font-size: medium;
  } */

}
