.profileedit {
    /* flex-direction: column; */
    width: 100%;
    display: flex;
    /* gap: 20px; */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: .9rem;
    line-height: 17px;
    justify-content: center;
    /* margin-left: 20%; */
    padding-top: 20px;
    padding-bottom: 50px;
    color: #C1C8E1;
    margin-top: 20px;
}


.profileedit_outer {
    display: flex;
    flex-direction: column;
    width: 60%;
    /* justify-content: center; */
    background-color: #F8F8FD;
    padding: 40px;
    margin: 40px;
    border-radius: 3px;
}

.profileedit_row1_img {
    justify-content: center;
    display: flex;
    /* background-color: #1D3178; */
    /* width: 60%; */
    padding-bottom: 40px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.profileedit_row1_img_circle {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-color: rgba(110, 48, 48, 0.696);
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.profileedit_row1_img_circle h1 {
    position: absolute;
}

.profileedit_row1_label_camera {
    display: none;
    width: 80%;
    /* border-bottom-left-radius: 50%; */
    margin-top: 55px;
    padding: 15px;
    justify-content: center;
    align-content: center;
    background-color: rgba(0, 0, 0, .25);
    cursor: pointer;
}

.profileedit_row1_img_circle:hover .profileedit_row1_label_camera {
    display: block;
    display: flex;

}

.profileedit_row1 {
    display: flex;
    flex-direction: column;
    /* width: 60%; */
}

.profileedit_row2 {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    display: flex;
    gap: 20px;
    /* border-bottom: 2px solid #8C9BD1; */
    width: 100%;
}

/* .profileedit_row1_label {
    justify-content: center;
    display: flex;
    width: 100%;
    background-color: gray;
} */


.profileedit_row2_row1 {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.profileedit_row2_row1_col1 input,
.profileedit_row2_row1_col2 input,
.profileedit_row2_row2 input,
.profileedit_row2_row3 input,
.profileedit_row2_row4_col1 input,
.profileedit_row2_row4_col2 input {
    border: none;
    padding: 10px;
    margin: 10px;
    outline: none;
    background-color: #F8F8FD;
}


.profileedit_row2_row1_col1,
.profileedit_row2_row1_col2,
.profileedit_row2_row4_col1,
.profileedit_row2_row4_col2 {
    width: 50%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #C1C8E1;
    margin-bottom: 10px;
}

.profileedit_row2_row1_col1,
.profileedit_row2_row4_col1 {
    margin-right: 10px;
}

.profileedit_row2_row1_col2,
.profileedit_row2_row4_col2 {
    margin-left: 10px;
}

.profileedit_row2_row3,
.profileedit_row2_row2 {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #C1C8E1;
    margin-bottom: 10px;
    width: 100%;

}

.profileedit_row2_row4 {
    display: flex;
    flex-direction: row;
}

.profileedit_row1_label {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 650;
    font-size: 2rem;
    line-height: 18px;
    font-weight: 700;
    color: #FB2E86;
    /* padding-right: 63px; */
    /* width: 60; */
    justify-content: center;
    display: flex;
    padding-bottom: 60px;
}
.profileedit_row1_img_circle_h1{
    font-size: 3rem;
}

.profileedit_row3 button {
    background: #FB2E86;
    border-radius: 2px;
    border: none;
    outline: none;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    /* line-height: 16px; */
    padding: 10px 30px;
    color: #FFFFFF;
    width: 100px;
    /* height: 44.06px; */

}

.profileedit_row3 {
    padding-top: 40px;
    justify-content: center;
    display: flex;
    padding-bottom: 10px;
    /* background-color: aquamarine; */
    /* padding-left: 18%; */
}

.profileedit_row2 label {
    /* padding: 10px; */
}


/* .profileedit_row2,.profileedit_row3{
    border-bottom: 2px solid #8C9BD1;
    padding-bottom: 10px;
} */


.profileedit ::placeholder {
    color: #C1C8E1;
}

@media only screen and (max-width: 500px) {
    .profileedit {
        /* width: 92.9%; */
        margin: 0;
        padding: 0;
    }

    .profileedit_outer {
        width: 83%;
        margin: 0;
        margin: 20px 0;
        display: flex;
        justify-content: center;
    }

    .profileedit_row1_label {
        font-size: 1.5rem;
    }

    .profileedit_row2 {
        display: flex;
    }

    .profileedit_row2_row1_col2 input,
    .profileedit_row2_row4_col2 input {
        width: 70%;
    }
}

@media only screen and (max-width:290px){
    .profileedit_outer{
        width: 90%;
    }
    .profileedit{
        width: 90%;
    }
}

@media only screen and (max-width:900) {

    .profileedit_row2_row1_col1 input,
    .profileedit_row2_row1_col2 input,
    .profileedit_row2_row2 input,
    .profileedit_row2_row3 input,
    .profileedit_row2_row4_col1 input,
    .profileedit_row2_row4_col2 input {
        padding: 0px;
    }

    .profileedit_row2 {
        width: 90%;
        padding: 10px;
        /* display:; */
    }
}

/********************toast/snackbar**************/

/* The snackbar - position it at the bottom and in the middle of the screen */

#snackbar {
    visibility: hidden;
    /* Hidden by default. Visible on click */
    min-width: 250px;
    /* Set a default minimum width */
    margin-left: -125px;
    /* Divide value of min-width by 2 */
    background-color: #333;
    /* Black background color */
    color: #fff;
    /* White text color */
    text-align: center;
    /* Centered text */
    border-radius: 2px;
    /* Rounded borders */
    padding: 16px;
    /* Padding */
    position: fixed;
    /* Sit on top of the screen */
    z-index: 1;
    /* Add a z-index if needed */
    left: 50%;
    /* Center the snackbar */
    bottom: 30px;
    /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
    visibility: visible;
    /* Show the snackbar */
    /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
    However, delay the fade out process for 2.5 seconds */
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@-webkit-keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}



/**************************************/