.loadmore {
  width: 100%;
}


.Loadmore_button {
  width: 100%;
  /* background-color: yellow; */
  display: flex;
  justify-content: center;
}

.Loadmore_button button {
  padding: 15px 30px;
  border-radius: 25px;
  /* display: flex; */
  border: none;
  outline: none;
  color: white;
  background-color: #fb2e86;
}

.Loadmore_button_loader {
  margin-left: 36%;
  position: absolute;
  /* margin: 0; */
}

.Loadmore_loader {
  border: 4px solid none;
  border-radius: 50%;
  border-top: 4px solid none;
  width: 10px;
  height: 10px;
  animation: spin 2s linear infinite;
  /* position: absolute; */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  /* margin-left: 45%;  */
  /* margin-top: 10%; */
}
.Loadmore_loader img{
  width: 50px;
  height: 50px;
  position: fixed;
  z-index: -1;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loadmore_hidden {
  display: none;
}