.signup {
    /* background-color: brown; */
    /* width: 40%; */
    justify-content: center;
    display: flex;
    padding-top: 50px;
    padding-bottom: 50px;
}

.signup_inner {
    display: flex;
    flex-direction: column;
    width: 65%;
    background-color: white;
    text-align: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 70px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.signup_inner h2 {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.6rem;
    color: #FB2E86;
}

.signup_inner h4 {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.signup_inner_row {
    padding: 10px;
    width: 80%;
}

.signup_inner_row input {
    width: 95%;
    padding: 10px;
    border: 1px solid #C2C5E1;
    color: #9096B2;
    border-radius: 2px;
    outline: none;
}

.signup_inner_row button {
    padding: 10px;
    width: 100%;
    background-color: #FB2E86;
    color: white;
    border: none;
    outline: none;
    border-radius: 3px;
    font-size: .95rem;
}

.signup_inner_row_label1 {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-bottom: 10px;
}

.signup_inner_row_label1 label {
    color: #9096B2;
    font-size: .85rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.signup_inner_row_label2 {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
}

.signup_inner_row_label2 label {
    /* padding-top: 10px; */
    color: #9096B2;
    font-size: .85rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

@media only screen and (max-width:1500px){
    .signup{
        width: 100%;
    }
}

@media only screen and (max-width: 500px) {
    .signup {
        display: flex;
        /* width: 90%; */
        /* margin: 0; */
        /* padding: 0; */
    }

    .signup_inner {
        display: flex;
        width: 100%;
    }
}




@media only screen and (max-width: 350px) {
    .signup {
        display: flex;
        width: 100%;
        /* margin: 0; */
        /* padding: 0; */
    }

    .signup_inner {
        display: flex;
        width: 100%;
    }
}

/********************toast/snackbar**************/

/* The snackbar - position it at the bottom and in the middle of the screen */

#snackbar {
    visibility: hidden;
    /* Hidden by default. Visible on click */
    min-width: 250px;
    /* Set a default minimum width */
    margin-left: -125px;
    /* Divide value of min-width by 2 */
    background-color: #333;
    /* Black background color */
    color: #fff;
    /* White text color */
    text-align: center;
    /* Centered text */
    border-radius: 2px;
    /* Rounded borders */
    padding: 16px;
    /* Padding */
    position: fixed;
    /* Sit on top of the screen */
    z-index: 1;
    /* Add a z-index if needed */
    left: 50%;
    /* Center the snackbar */
    bottom: 30px;
    /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
    visibility: visible;
    /* Show the snackbar */
    /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
    However, delay the fade out process for 2.5 seconds */
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@-webkit-keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}