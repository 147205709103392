  .Wishlist_right_cont {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    /* border-bottom: 1px solid #6b6bbe; */
  }
  .wishlist_right_cont_navigation{
    display: flex;
  justify-content: center;
  /* margin-top: -20px; */
  padding-top: 30px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: .85rem;
  font-weight: 500;
  text-decoration: underline;
  color: #15245e;
  
  }

  .Wishlist {
    /* display: flex; */
    flex-direction: row;
    padding: 10px;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
  }

  .Wishlist_right {
    width: 50%;
    display: flex;
    justify-content: center;
  }

  .singlewishlist_button_btn2 {
    /* margin-left: 50px; */
    padding: 3px;
  }

  .wishlist_button_btn2 {
    margin-left: 50px;
    padding: 3px;
    justify-content: right;
    display: flex;
    padding-top: 20px;
    width: 66%;
  }

  .singlewishlist_button_btn2 button {
    /* display: flex; */

    background: #fb2e86;
    border-radius: 2px;
    border: none;
    outline: none;
    color: white;
    padding: 5px;
  }

  .wishlist_button_btn2 button {
    /* display: flex; */

    background: #fb2e86;
    border-radius: 2px;
    border: none;
    outline: none;
    color: white;
    padding: 10px;
  }


  .Wishlist_right_header {
    display: flex;
    flex-direction: row;
    width: 87.4%;
    /* column-gap: 270px; */
    /* margin-bottom: 10px; */
    /* justify-content: space-between; */
  }

  .Wishlist_right_header_h1 {
    font-size: 20px;
    line-height: 23px;
    font-family: "segoe ui";
    color: #fb2e86;
    font-weight: bold;
    width: 48%;
    /* background-color: yellow; */
  }

  .Wishlist_right_header_h2 {
    width: 20%;
    font-size: 20px;
    line-height: 23px;
    font-family: "segoe ui";
    color: #fb2e86;
    font-weight: bold;
    /* background-color: gold; */
    /* padding-left: 30px; */

  }


  .singlewishlist_product_label_l1 {
    font-family: "segoe ui";
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: #000000;
  }

  .singlewishlist_product_label_l2,
  .singlewishlist_product_label_l3 {
    font-size: 12px;
    line-height: 14px;
    font-family: "segoe ui";
    color: #a1a8c1;
  }

  .singlewishlist_product {
    display: flex;
    flex-direction: row;
    width: 60%;
    /* margin-right: 100px; */
    align-items: center;
    /* background-color: yellow; */
  }

  .singlewishlist_price {
    /* background-color: gold; */
    width: 30%;
  }

  .checkout_left_calculate {
    display: flex;
    flex-direction: column;
  }

  .checkout_left_calculate input {
    display: flex;
    flex-direction: column;
  }

  .singlewishlist_product_label {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }

  .singlewishlist_product img {
    height: 100px;
    width: 100px;
  }

  .singlewishlist {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 70%;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #8b8bd8;
    /* gap: 20px; */
  }
  .wishlist_button_btn3{
    margin-top: 15px;
  }
  .wishlist_button_btn3 button{
    padding: 5px 22.5px;
  }

@media only screen and (max-width:1200px){
  .Wishlist_right_header_h1 {
    width: 40%;
  }
  .Wishlist_right_header_h2{
    display: flex;
    justify-content: center;
    /* width: 20%; */
  }
}

  @media only screen and (max-width:768px){
    .Wishlist_right_header_h1 {
      width: 40%;
    }

  }

  @media only screen and (max-width:550px){
    .singlewishlist{
      width: 80%;
    }
    .singlewishlist_price{
      width: 40%;
      display: flex;
      justify-content: center;
    }
    .singlewishlist_product{
      width: 40%;
    }
    .singlewishlist_product img{
      width: 70px;
      height: 70px;
    }
    .Wishlist_right_header_h2{
      width: 30%;
      display: flex;
      justify-content: center;
    }
  }

  @media only screen and (max-width: 500px) {
    .Wishlist {
      width: 80%;
    }

    .wishlist_right {
      width: 135%;
      margin-left: -30PX;
    }
    .singlewishlist_product_label{
      padding-left: 10px;
    }

    .Wishlist_right_header {
      /* column-gap: 260px; */
      width: 100%;
    }


    .Wishlist_right_header_h1 {
      width: 40%;
    }

    .Wishlist_right_header_h2 {
      /* margin-left: -80px; */
      display: flex;
      justify-content: right;
      width: 24%;
    }

    .singlewishlist {
      width: 90%;
    }

    .singlewishlist_price {
      /* margin-left: -30px; */
      display: flex;
      justify-content: center;
      width: 30%;
    }

    .singlewishlist_product {
      width: 50%;
    }

    .singlewishlist_product img {
      width: 50px;
      height: 50px;
    }

    .singlewishlist_button_btn2 button {
      /* width: auto; */
      /* padding-left: 10px;
      padding-right: 10px; */
    }
  }


  @media only screen and (max-width: 350px) {
    .Wishlist {
      width: 90%;
    }

    .wishlist_right {
      width: 100%;

    }

    .Wishlist_right_header_h1 {
      width: 50%;
      display: flex;
    }

    .Wishlist_right_header_h2 {
      /* margin-left: -80px; */
      width: 30%;
      display: flex;
    }

    .singlewishlist {
      width: 100%;
    }

    .singlewishlist_price {
      /* width: ; */
      /* margin-left: -30px; */
    }

    .singlewishlist_product {
      width: 50%;
    }

    .singlewishlist_product img {
      width: 40px;
      height: 40px;
    }

    .singlewishlist_button_btn2 button {
      /* width: auto; */
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  @media only screen and (max-width:290px){
    .wishlist{
      margin-left: -10px;
    }
    .singlewishlist{
      width: 125%;
    }
    .Wishlist_right_header{
      width: 120%;
    }
    .singlewishlist_price {
      font-size: .85rem;
    }
  }

  
  /* @media only screen and (max-width: 900px) {
    
    .Wishlist_right_header{
      gap: 200px;
    }
  } */

  /********************toast/snackbar**************/

  /* The snackbar - position it at the bottom and in the middle of the screen */

  #snackbar {
    visibility: hidden;
    /* Hidden by default. Visible on click */
    min-width: 250px;
    /* Set a default minimum width */
    margin-left: -125px;
    /* Divide value of min-width by 2 */
    background-color: #333;
    /* Black background color */
    color: #fff;
    /* White text color */
    text-align: center;
    /* Centered text */
    border-radius: 2px;
    /* Rounded borders */
    padding: 16px;
    /* Padding */
    position: fixed;
    /* Sit on top of the screen */
    z-index: 1;
    /* Add a z-index if needed */
    left: 50%;
    /* Center the snackbar */
    bottom: 30px;
    /* 30px from the bottom */
  }

  /* Show the snackbar when clicking on a button (class added with JavaScript) */
  #snackbar.show {
    visibility: visible;
    /* Show the snackbar */
    /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }

  /* Animations to fade the snackbar in and out */
  @-webkit-keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }

    to {
      bottom: 30px;
      opacity: 1;
    }
  }

  @keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }

    to {
      bottom: 30px;
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeout {
    from {
      bottom: 30px;
      opacity: 1;
    }

    to {
      bottom: 0;
      opacity: 0;
    }
  }

  @keyframes fadeout {
    from {
      bottom: 30px;
      opacity: 1;
    }

    to {
      bottom: 0;
      opacity: 0;
    }
  }