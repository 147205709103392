@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

.OrderCompleted_outer {
  width: 100%;
  /* background-color: #F5F5F5; */
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}

.OrderCompleted_outer_container_row {
  width: 85%;
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  /* margin-top: 100px; */
}

/* ---------column1--------- */
.OrderCompleted_col1 {
  /* width: 20%; */
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

.OrderCompleted_col1_row1 {
  width: 20%;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  margin-top: 123px;
}

.OrderCompleted_col1_row2 {
  /* width: 20%; */
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

.OrderCompleted_col1_row2_bordercol1 {
  width: 20%;
  padding-bottom: 280px;
  border-left: 1px dashed #d2d1d1;
  margin-left: 40px;
}

.OrderCompleted_col1_row2_bordercol2 {
  /* width: 30%; */
  padding-bottom: 40px;
  border-top: 1px dashed #d2d1d1;
  margin-left: 40px;
}

/* -----------column3-------- */
.OrderCompleted_col3 {
  /* width: 20%; */
  display: flex;
  justify-content: left;
  flex-direction: column;
  /* margin-left: 60px; */
}

.OrderCompleted_col3_row1 {
  /* width: 20%; */
  display: flex;
  justify-content: left;
  margin-bottom: 300px;
  margin-top: 120px;
}

.OrderCompleted_col3_row2 {
  /* width: 20%; */
  margin-top: 15px;
  display: flex;
  justify-content: left;
  /* flex-direction:row; */
}

/* .OrderCompleted_col3_row2_col1{
    width: 20%;
    display: flex;
    justify-content:right;
    margin-top: 25px;
    border-top: 1px dashed #D2D1D1;

} */

/* --------------column2---row------- */
.OrderCompleted_container_col2_row {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.OrderCompleted_innercont_col2_row {
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 12px;
}

.OrderCompleted_cont_col2_row1 {
  width: 15%;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

.OrderCompleted_cont_col2_row1_img1 {
  width: 15%;
  display: flex;
  justify-content: center;
  background-attachment: fixed;
  z-index: 0;
}

.OrderCompleted_cont_col2_row1_img2 {
  /* width: 15%; */
  width: 65px;
  height: 65px;
  background-color: rgb(193, 76, 76);
  border: none;
  border-radius: 35px;

  display: flex;
  justify-content: center;
  z-index: 1;
  margin-right: 45px;
}

.OrderCompleted_cont_col2_row1_img2 img {
  /* width: 25%; */
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  z-index: 2;
}

.OrderCompleted_cont_col2_row2 {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
}

.OrderCompleted_cont_col2_row2 label {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #101750;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1.8rem;
  font-weight: 650;
  text-align: center;
  padding: 15px;
}

.OrderCompleted_cont_col2_row3 {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
}

.OrderCompleted_cont_col2_row3 label {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #8d92a7;
  text-align: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1rem;
  font-weight: 500;
}

.OrderCompleted_cont_col2_row4 {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
  margin-top: 50px;
}

.OrderCompleted_cont_col2_row4 button {
  width: 50%;
  padding: 25px;
  display: flex;
  justify-content: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.9rem;
  font-weight: 500;
  background-color: #ff1788;
  color: white;
  text-align: center;
  border: none;
  border-radius: 7px;
}

.OrderCompleted_container_col2_row2 {
  width: 100%;
  border-top: 1px dashed #d2d1d1;
  margin-top: 45px;
}

/****************************/

@media only screen and (max-width:550px){
  .OrderCompleted_cont_col2_row4 button{
    width: 60%;
  }
  .OrderCompleted_innercont_col2_row{
    width: 100%;
    margin: -8px;
  }
}
@media only screen and (max-width: 500px) {
  .OrderCompleted_outer {
    width: 99%;
  }

  .OrderCompleted_outer_container_row {
    display: flex;
  }

  .OrderCompleted_col1_row1 img {
    width: 63px;
  }

  .OrderCompleted_cont_col2_row4 button {
    display: flex;
    width: auto;
  }
  .OrderCompleted_cont_col2_row4{
    margin-top: 10px;
  }

  .OrderCompleted_cont_col2_row2 {
    display: flex;
    /* margin-top:0px; */
  }
  .OrderCompleted_innercont_col2_row {
    width: 100%;
    margin: 1px;
  }
}

@media only screen and (max-width:415px){
  .OrderCompleted_innercont_col2_row{   
    margin: -27px;
  }
}

@media only screen and (max-width:395px){
  .OrderCompleted_cont_col2_row2 label{
    padding: 0px;
  }
  .OrderCompleted_col1_row2_bordercol1{
    padding-bottom: 215px;
  }
  .OrderCompleted_col3_row1 {
    margin-bottom: 250px;
  }
}

@media only screen and (max-width:380px){
  .OrderCompleted_cont_col2_row1{
    margin-bottom: 0px;
  }
  .OrderCompleted_cont_col2_row2{
    margin-bottom: 0px;
  }
  .OrderCompleted_innercont_col2_row {
    width: 110%;
    margin:14px;
  }
  .OrderCompleted_col1_row2_bordercol1{
    padding-bottom: 261px;
  }
}

@media only screen and (max-width: 360px) {
  .OrderCompleted_outer {}

  .OrderCompleted_outer_container_row {
    display: flex;
  }

  .OrderCompleted_col1_row1 img {
    width: 63px;
  }

  .OrderCompleted_cont_col2_row4 button {
    display: flex;
    width: auto;
  }

  .OrderCompleted_cont_col2_row2 {
    display: flex;
  }
  .OrderCompleted_col3_row1{
    margin-top: 170px;
  }
  .OrderCompleted_innercont_col2_row {
    margin: -15px;
  }
}

@media only screen and (max-width:290px){
  .OrderCompleted_col1,
  .OrderCompleted_col3_row2{
    display: none;
}
}

