.shippingpage_menubar_mobview {
    display: none;
}
.shippingpage_header{
    position: sticky;
    top: 0;
}

@media only screen and (max-width: 500px) {
    .shippingpage_menubar_mobview {
        display: flex;
        position: absolute;
        margin-top: -220px;
        width: 100%;
    }
}

@media only screen and (max-width: 350px) {
    .shippingpage_menubar_mobview {
        display: flex;
        position: absolute;
        margin-top: -240px;
        width: 100%;
    }
}