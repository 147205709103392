.loaderpage {
    display: flex;
    justify-content: center;
}
.singleproductpage{
    width: 99.7%;
}
.singleproductpage_header{
    position: sticky;
    top: 0;
    background-color: #c90053cf;
}
.singleproductpage_filterbar_bottom{
    display: none;
}

.singleproductpage_menubar_mobview {
    display: none;
}

.singleproductpage_whatsapp{
    position: sticky;
    bottom: 40px;
    display: block;
    margin-top: -60px;
    width: 95%;
}

@media only screen and (max-width: 500px) {
    .singleproductpage_menubar_mobview {
        display: flex;
        position: absolute;
        margin-top: -220px;
        width: 100%;
    }
    .singleproductpage_filterbar_bottom{
        display: block;
        bottom: 0;
        position: sticky;
        background-color: #FFFF;
    }
}

@media only screen and (max-width: 350px) {
    .singleproductpage_menubar_mobview {
        display: flex;
        position: absolute;
        margin-top: -240px;
        width: 100%;
    }
}

@media only screen and (max-width:290px){
    .singleproductpage_filterbar_bottom{
        width: 99%;
    }
}