.orderdetails {
    /* margin-top: px; */
    /* margin-left: 7%; */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding-top: 50px;
}

.orderdetails_row1 {
    width: 80%;
    display: flex;
    flex-direction: row;
    /* background-color: yellow; */
    padding: 20px;
    border: none;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    /* justify-content: center; */
}

.orderdetails_row1_col1 {
    /* background-color: rosybrown; */
    width: 20%;
    justify-content: center;
    display: flex;
}

.orderdetails_row1_col2 {
    /* background-color:beige ; */
    display: flex;
    flex-direction: column;
    width: 80%;
    padding-left: 20px;
}

.orderdetails_row1_col2 h4 {
    margin: 0;
    padding: 0;
    display: flex;
    color: #FB2E86;
}

.orderdetails_row1_col2 span {
    color: #111C85;
    padding-top: 5px;
    font-weight: 500;
    font-size: .9rem;
}

.orderdetails_row1_col2 label {
    padding-top: 10px;
    color: #9295AA;
    font-size: .85rem;
}

.orderlist_space_1 {
    padding-left: 20px;
}

.orderlist_space_2 {
    padding-left: 68.5px;
}

.orderlist_space_3 {
    padding-left: 14px;
}


/***********************row1 end***************/
.orderdetails_row2 {
    padding-top: 50px;
    padding-bottom: 80px;
}

.orderdetails_row2_div {
    width: 1px;
    background-color: #E1E1E1;
    height: 15vh;
    display: flex;
    margin-left: 13.5%;
}

.singleorderdetails {
    display: flex;
    align-items: center;
    margin-left: 10%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.singleorderdetails_row1 {
    width: 10%;
}

.singleorderdetails_row2 {
    width: 80%;
    /* padding-top: 20px; */
}

.singleorderdetails_row2 h4 {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: .9rem;
    padding: 0;
    margin: 0;
    color: #FB2E86;
}

.singleorderdetails_row2 label {
    font-size: .7rem;
    color: #9295AA;

}

.singleorderdetails_row1_circle {
    display: flex;
    width: 61px;
    height: 61px;
    background-color: rgb(0, 0, 0) transparent;
    border-radius: 50%;
    border: 1px solid #E1E1E1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #88C283;
}

.singleorderdetails_row1_circle img {
    padding-left: 10px;
}









/********************button****************/

.orderdetails_row3 {
    padding-left: 15%;
    display: flex;
    padding-top: 20px;
    padding-bottom: 30px;
}

.orderdetails_row3 button {
    padding: 15px 40px;
    border: none;
    outline: none;
    border-radius: 2px;
    color: white;
    background-color: #FB2E86;
}


/********************************************/

@media only screen and (max-width:1500px){
    .singleorderdetails_row1{
        width: 15%;
    }
    .orderdetails_row2_div{
        margin-left: 16.5%;
    }
    .singleorderdetails_row1_circle img{
        padding-left: 0px;
    }
}

@media only screen and (max-width:830px){
    .orderdetails{
        margin-left: 0px;
    }
    .singleorderdetails_row2{
        margin-left: 15px;
    }
}

@media only screen and (max-width:550px){
    .orderdetails_row1{
        width: 110%;
        margin-left: -15px;
    }
    .singleorderdetails_row2{
        margin-left: 35px;
    }
}

@media only screen and (max-width: 500px) {

    /* .orderdetails_row1{
        width: 100%;
    }
    .orderdetails_row1_col1{
        align-items: center;
    }
    .orderdetails_row1_col1 img{
        max-width: 60px;
        height: 100px;
    }
    .singleorderdetails_row2 h4,
    .singleorderdetails_row2 label{
        padding-left: 50px;
    }
    .orderdetails_row2_div{
        margin-left: 10%;
    } */
    .orderdetails {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: -11px;

    }

    .singleorderdetails {
        display: flex;
        flex-direction: row;
        width: 130%;
        margin-left: 0px;
        padding-left: 0;
    }

    .orderdetails_row2_div {
        margin-left: 31px;
    }

    .orderdetails_row1 {
        display: flex;
        flex-direction: column;
        width: 150%;
        margin: 0;
        margin-left: -69px;
    }

    .orderdetails_row1_col1 {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .singleorderdetails_row1{
        width: 19%;
    }
    .singleorderdetails_row2 {
        /* margin-left: 40px; */
    }
}

@media only screen and (max-width:420px){
    .orderdetails{
        margin-left: 0px;
    }
    .singleorderdetails_row2{
        margin-left: 30px;
    }
}

@media only screen and (max-width: 350px) {
    .orderdetailpage_row2 {}

    .orderdetails {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .singleorderdetails_row1{
        width: 16%;
    }
    .singleorderdetails_row2{
        /* width: 15%; */
        padding-left: 0px;
    }
    .singleorderdetails {
        display: flex;
        flex-direction: row;
        width: 130%;
    }

    .orderdetails_row2_div {
        margin-left: 31px;
    }

    .orderdetails_row1 {
        display: flex;
        flex-direction: column;
        width: 150%;
        margin: 0;
        margin-left: -50px;
    }

    .orderdetails_row1_col1 {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width:290px){
    .orderdetails_row1{
        width: 145%;
        /* margin-left: 5px; */

    }
    .singleorderdetails_row2{
        margin-left: 40px;
    }
    .orderdetails_row1_col2{
        padding-left: 0px;
    }
}

/* @media only screen and (max-width: 900px) {
    .singleorderdetails_row2{
        margin-left: 25px;
    }
    .orderdetails_row2_div{
        display: flex;
        margin-left: 80px;
    }
} */