@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

.LatestProducts_outer {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  /* position: absolute; */
  align-items: center;
  padding-top: 3%;
}
.latestproduct_loader{
  margin-left:48% ;
  margin-top: 6%;
  position: absolute;
  padding:10px 0;
}

.LatestProducts_header {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #fb2e86;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 2rem;
  font-weight: 650;
  line-height: 49.22px;
  /* position:absolute; */
  margin-top: 10px;
  /* padding: 10px; */
}

.LatestProducts_imgchair_col2 img {
  width: 245px;
  height: 245px;
  /* width: 230px;
    height: 230px; */
}

.LatestProducts_header h4 {
  font-size: 2rem;
  /* font-weight: 650; */
  margin-top: 0;
}

.LatestProducts_menu {
  width: 50%;
  display: flex;
  justify-content: space-around;
  /* margin-top: 10px; */
  /* position: absolute; */
}

.LatestProducts_menu_col {
  /* width: 20%; */
  color: #151875;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  /* font-style: normal; */
  font-weight: 500;
  font-size: 1rem;
  font-weight: 500;
  /* line-height: 21.6px; */
  /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
}

.LatestProducts_menu_col:hover {
  color: #fb4997;
  border-bottom: 2px solid #fb4997;
}

.latestproduct_section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  justify-content: space-evenly;
  margin-top: 70px;
  /* margin-left: 30px; */
  width: 80%;
  margin: auto;
  /* margin-top: 5%; */
}

.LatestProducts_outerimage_container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  /* flex-direction: column; */
  margin-top: 1%;
  margin-left: 30px;
  /* display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto; */
}

.LatestProducts_inner_outerimage1_container1_row1 {
  width: 100%;
  display: flex;
  /* justify-items: center; */
  /* justify-content: space-evenly; */
  /* flex-direction: row; */
  /* margin-bottom: 60px; */
}

.LatestProducts_inner_outerimage2_container2_row2 {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  margin-top: 30px;
  margin-top: 10px;
}

.LatestProducts_inner_inner_imagefull_outer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 60px;
  
}

.LatestProducts_inner_inner_imagefull {
  width: 70%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* padding-left: 50px; */
  /* margin-right: 0px; */
  /* padding-bottom: 10px; */
  /* background-color: gold; */
}

.LatestProducts_inner_top_image_row1 {
  background-color: #fcf8f8;
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 12px;
}

/* .LatestProducts_inninnerimage_row :hover{
    background-color: white;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
} */

.LatestProducts_imagecol2 {
  width: 75%;
  /* background-color: #F7F7F7; */
  display: flex;

  justify-content: center;
  align-items: center;
}

.LatestProducts_icons_row1_col1 {
  visibility: hidden;
  /* visibility:hidden; */
  /* opacity:0; */
  /* transition:visibility 0.3s linear,opacity 0.3s linear; */

  width: 15%;
  /* background-color: red; */
  background-color: #f7f7f7;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin-left: 5px;
  padding-bottom: 10px;
}

.LatestProducts_inner_top_image_row1:hover .LatestProducts_icons_row1_col1 {
  visibility: visible;
  /* transition: all 1s ease  */
}

.LatestProducts_imagecol2 img {
  width: 80%;
  height: 80%;
  display: flex;
  max-width: 150px;

  /* margin-left: 60px; */
  margin-bottom: 10px;
  padding-top: 5px;
}

.LatestProducts_icon_imgsale_col1_row1 {
  /* width: 10%;
    display: flex;
    justify-content: center; */
  width: 20px;
  display: flex;
  justify-content: center;
  margin-left: 35px;
  margin-bottom: 50px;
}

/* .LatestProducts_imgsale_col1 img{
    

} */
.LatestProducts_icons_col1_row2 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

/* .LatestProducts_icons_col1_row2 img{
    width:20px;
    display: flex;
    justify-content: center;
    margin-left: 15px;
    margin-bottom: 10px;

} */
.LatestProducts_imageicons1 {
  background-color: #eeeffb;
  width: 35px;
  height: 35px;
  border-radius: 15px;
  margin-left: 15px;
  margin-bottom: 10px;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LatestProducts_imageicons1 img {
  width: 30px;

  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 5px; */
  margin-bottom: 10px;
  margin-right: 10px;
  margin-top: 10px;
}

.LatestProducts_imageicons2 img {
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  margin-bottom: 15px;
}

.LatestProducts_bottom_imgchair_text_row2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-bottom: 10px;
  margin-top: 10px;
  padding-top: 5px;
  /* background-color: yellow; */
}

.LatestProducts_bottom_imgchair_lefttext_row2 {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  color: #fb2e86;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: .9rem;
  font-weight: 500;
  /* line-height: 18.75px; */
  border-bottom: 2px solid #eeeffb;
  transform: matrix(1, 0.01, -0.01, 1, 0, 0);
}

.LatestProducts_bottom_imgchair_righttext_row2 {
  width: 50%;
  display: flex;
  justify-content: right;
}
.LatestProducts_bottom_imgchair_lefttext_row2_lab{
  font-size: .8rem;
  padding-top: 5px;
}
.LatestProducts_bottom_imgchair_righttext_row2 label {
  padding-left: 10px;
  color: #151875;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 16.41px;
}

.LatestProducts_bottom_imgchair_righttext_row2 span {
  /* padding-left: 10px; */
  color: red;
  font-family: "Josefin Sans", sans-serif;
  font-size: 14px;
  line-height: 16.41px;
  text-decoration-line: line-through;
  padding-left: 10px;
}

@media only screen and (max-width:1500px){
  .LatestProducts_imgchair_col2 img{
    width:180px;
    height:180px;
  }
  .LatestProducts_bottom_imgchair_lefttext_row2{
    font-size:.8rem ;
  }
  .LatestProducts_bottom_imgchair_lefttext_row2_lab{
    font-size: .7rem;
  }
}

@media only screen and (max-Width:1200px){
  .LatestProducts_inner_outerimage1_container1_row1{
    width: 80%;
   }
  .LatestProducts_imgchair_col2 img{
    width:200px;
    height:200px;
  }
  .LatestProducts_outerimage_container{
    /* margin-left: 0px; */
  }
  .latestproduct_section{
    width:100%;
  }
  .LatestProducts_inner_inner_imagefull {
    padding-left: 0px;
    margin-right: 10px;
    width: 90%;
  }
  .LatestProducts_bottom_imgchair_lefttext_row2{
    font-size:.8rem ;
  }
  .LatestProducts_bottom_imgchair_lefttext_row2_lab{
    font-size: .7rem;
  }
  .latestproduct_loader{
    margin-top: 8.5%;
  }
.LatestProducts_inner_inner_imagefull_outer {
  margin-bottom: 40px;
}
}


@media only screen and (max-width:900px){
  .LatestProducts_imgchair_col2 img{
    width:150px;
    height:150px;
  }
 .LatestProducts_inner_outerimage1_container1_row1{
  width: 80%;
 }
 .LatestProducts_bottom_imgchair_lefttext_row2{
  font-size:.65rem ;
}
.LatestProducts_bottom_imgchair_lefttext_row2_lab{
  font-size: .55rem;
}
.LatestProducts_bottom_imgchair_righttext_row2 label{
  font-size: .7rem;
}
}

@media only screen and (max-width:750px){
  .LatestProducts_bottom_imgchair_lefttext_row2{
    font-size:.55rem ;
  }
  .LatestProducts_bottom_imgchair_lefttext_row2_lab{
    font-size: .45rem;
  }
  .LatestProducts_bottom_imgchair_righttext_row2 label{
    font-size: .6rem;
  }
  .LatestProducts_imgchair_col2 img{
    width: 130px;
    height: 130px;
  }
}
@media only screen and (max-width:625px){
  .LatestProducts_imgchair_col2 img{
    width: 120px;
    height: 120px;
  }
  
}
@media only screen and (max-width: 500px) {
  .latestproduct_loader {
    /* width: 70%; */
    margin-top: 70px;
  }
  .LatestProducts_inner_inner_imagefull{
    padding-left: 0px;
    /* width: 80%; */
    display: flex;
    justify-content: center;
    /* padding: 50px; */
  }
  .LatestProducts_inner_top_image_row1 {
    background-color: #fcf8f8;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 12px;
  }
.LatestProducts_outer{
  display: flex;
  justify-content: center;
}
  .LatestProducts_outerimage_container {
    /* display: flex; */
    margin-top: 30px;
    margin-left: 0;
    justify-content: center;
  }
  .latestproduct_section{
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    margin-right: 0px;
    justify-content: center;
  }
  .LatestProducts_inner_inner_imagefull_outer{
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .LatestProducts_inner_outerimage1_container1_row1{
    display: flex;
    justify-content: center;
  }
  .LatestProducts_imgchair_col2 img{
    width:200px;
    height:200px;
  }
  .LatestProducts_bottom_imgchair_lefttext_row2 {
    font-size: .9rem;
  }
  
  .LatestProducts_bottom_imgchair_lefttext_row2_lab{
    font-size: .8rem;
  }
  .LatestProducts_bottom_imgchair_righttext_row2 label {
    font-size: 0.8rem;
  }
  
}

@media only screen and (max-width: 350px) {
  .LatestProducts_header h4 {
    font-size: 1.8rem;
  }

  .LatestProducts_menu label {
    font-size: .7rem;
  }
  .LatestProducts_bottom_imgchair_lefttext_row2 label{
    font-size: .65rem;
  }
  .LatestProducts_imgchair_col2 img{
    width: 130px;
    height: 130px;
  }
.latestproduct_section{
  width: 100%;
}
}

/* @media only screen and (max-width: 900px) {
  .latestproduct_section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    justify-content: space-evenly;
    margin-top: 70px;
    margin-left: 30px;
    width: 80%;
    margin: auto;
  }
} */