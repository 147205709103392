.Latestblog_row1 {
  display: flex;
  flex-direction: row;
  /* background-color: #fb499712; */
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.Latestblog_row1 label {
  font-size: 2rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #fb2e86;
  font-weight: bold;
}

.Latestblog_row2_outer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  /* background-color: yellow; */
  margin-bottom: 10px;
}

.Latestblog_row2 {
  display: flex;
  flex-direction: row;
  width: 25%;
  justify-content: center;
  /* background-color: gainsboro; */
  /* margin-bottom: 10px; */
  margin: 10px;
}

.Latestblog_row2_col1 {
  width: 94%;
  /* background-color: green; */
}

.Latestblog_row2_col1_image img {
  width: 100%;
  border-radius: 20px;
}

.Latestblog_row2_col1_text_l1,
.Latestblog_row2_col1_text_l2,
.Latestblog_row2_col1_text_l3,
.Latestblog_row2_col1_text_l4 {
  padding-bottom: 25px;
  /* padding-left: 20px;  */
  display: flex;
}


.Latestblog_row2_col1_text_l1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #151875;
  font-size: 0.8rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.Latestblog_row2_col1_text_l2 {
  color: #151875;
  font-size: 1rem;
  font-weight: bold;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.Latestblog_row2_col1_text_l3 {
  color: #72718f;
  font-size: 0.9rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.Latestblog_row2_col1_text_l4 button {
  border: none;
  color: #fb2e86;
  background-color: white;
  font-size: 0.8rem;
  cursor: pointer;
}

.Latestblog_row2_col1_text_l4 button:hover {
  text-decoration: underline;
}

.Latestblog_row2_col1 {
  background-color: white;
  /* padding-right: 20px; */
}

.Latestblog_row2_col1_text_l3 label {
  display: flex;
  flex-direction: row;
}

.Latestblog_row2_col1_text_l1_p1 {
  padding-right: 15px;
}

.Latestblog_row2_col2_text_l1,
.Latestblog_row2_col2_text_l2,
.Latestblog_row2_col2_text_l3,
.Latestblog_row2_col2_text_l4 {
  padding-bottom: 15px;
  padding-left: 20px;
}

.Latestblog_row2_col2_text_l1 {
  display: flex;
  flex-direction: row;
  color: #151875;
  font-size: 0.8rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.Latestblog_row2_col2_text_l2 {
  color: #fb2e86;
  font-size: 1rem;
  font-weight: bold;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.Latestblog_row2_col2_text_l3 {
  color: #72718f;
  font-size: 0.9rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.Latestblog_row2_col2_text_l4 button {
  border: none;
  color: #fb2e86;
  background-color: white;
  font-size: 0.8rem;
  cursor: pointer;
}

.Latestblog_row2_col2_text_l4 button:hover {
  text-decoration: underline;
}

.Latestblog_row2_col2 {
  background-color: white;
  padding-right: 20px;
}

.Latestblog_row2_col2_text_l3 label {
  display: flex;
  flex-direction: row;
}

.Latestblog_row2_col2_text_l1_p1 {
  padding-right: 15px;
}

.Latestblog_row2_col3_text_l1,
.Latestblog_row2_col3_text_l2,
.Latestblog_row2_col3_text_l3,
.Latestblog_row2_col3_text_l4 {
  padding-bottom: 15px;
  padding-left: 20px;
}

.Latestblog_row2_col3_text_l1 {
  display: flex;
  flex-direction: row;
  color: #151875;
  font-size: 0.8rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.Latestblog_row2_col3_text_l2 {
  color: #151875;
  font-size: 1rem;
  font-weight: bold;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.Latestblog_row2_col3_text_l3 {
  color: #72718f;
  font-size: 0.9rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.Latestblog_row2_col3_text_l4 button {
  border: none;
  color: #fb2e86;
  background-color: white;
  font-size: 0.8rem;
  cursor: pointer;
}

.Latestblog_row2_col3_text_l4 button:hover {
  text-decoration: underline;
}

.Latestblog_row2_col3 {
  background-color: white;
}

.Latestblog_row2_col3_text_l3 label {
  display: flex;
  flex-direction: row;
}

.latestblog_loader {
  position: absolute;
  margin-left: 48%;
  margin-top: 90px;
}

.Latestblog_row2_col3_text_l1_p1 {
  padding-right: 15px;
}

@media only screen and (max-width: 350px) {

  /* .Latestblog_row1{
    display: flex;
  } */
  .Latestblog_row2 {
    display: contents;
    flex-direction: column;
    /* width: 60%; */
    /* margin-left: 29px; */
    /* padding-left:0px; */
  }

  .Latestblog_row2_col1_image,
  .Latestblog_row2_col2_image,
  .Latestblog_row2_col3_image {
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }

  .Latestblog_row2_col1_text,
  .Latestblog_row2_col2_text,
  .Latestblog_row2_col3_text {
    display: flex;
    flex-direction: column;
    text-align: justify;
  }

  .Latestblog_row2_col1_text_l1,
  .Latestblog_row2_col2_text_l1,
  .Latestblog_row2_col3_text_l1 {
    display: flex;
    justify-content: center;
  }

}


@media only screen and (max-width: 500px) {
  .Latestblog_row1 {
    padding-bottom: 0px;
  }

  .Latestblog_row2_outer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* margin-left: 10px; */
  }

  .Latestblog_row2_col1_text {
    padding-left: 10px;
  }

  .Latestblog_row2 {
    display: contents;
    flex-direction: column;
    /* width: 60%; */
    /* margin-left: 29px; */
    /* padding-left:0px; */
  }

  .Latestblog_row2_col1_image,
  .Latestblog_row2_col2_image,
  .Latestblog_row2_col3_image {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    margin-left: 40px;
  }

  .Latestblog_row2_col1_text,
  .Latestblog_row2_col2_text,
  .Latestblog_row2_col3_text {
    display: flex;
    flex-direction: column;
    text-align: justify;
  }

  .Latestblog_row2_col1_text_l1,
  .Latestblog_row2_col2_text_l1,
  .Latestblog_row2_col3_text_l1 {
    display: flex;
    justify-content: center;
  }

}

@media only screen and (max-width: 900px) {
  .Latestblog_row2_outer {
    display: flex;
    flex-direction: column;
    width: 99%;
  }

  .Latestblog_row1 {
    width: 99.8%;
  }

  .Latestblog_row2 {
    width: 99%;
  }
}